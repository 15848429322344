import React, {useContext} from 'react';
import {Button, Form, Input, Modal, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import useApi from 'core/hooks/useAPI';
import {EntryContext} from 'core/state/EntryProvider';
import {forgotPassVerify} from 'core/apiRequests';

import {SHOW_LOGIN} from 'views/Entry';

import Spacer from '../Spacer';

const {Title, Text} = Typography;


export default function ForgotPassVerify() {

  const [state, setState] = useContext(EntryContext);
  const {t} = useTranslation();
  const [trigger, loading] = useApi();

  const passMatchRule = ({getFieldValue}) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('entry.register.passNotMatch')));
    },
  });

  const onFinish = (values) => {
    trigger(forgotPassVerify(state.email, values.otp, values.password))
      .then(() => {
        setState({
          current: SHOW_LOGIN
        });
        Modal.success({
          title: t('entry.reset.passwordChanged'),
          content: t('entry.reset.passwordChangedDesc'),
        });
      })
      .catch(err => {
        Modal.error({
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark="optional"
    >

      <Title level={4}>
        {t('entry.reset.title')}
      </Title>
      <Text>
        {t('entry.reset.subtitle',
          {email: state.email})}
      </Text>

      <Spacer height={12}/>

      <Form.Item
        label={t('entry.reset.otp')}
        name="otp"
        rules={[{required: true, message: t('entry.reset.otpRequired')}]}
      >
        <Input size="middle"/>
      </Form.Item>

      <Form.Item
        label={t('entry.register.password')}
        name="password"
        rules={[{required: true, message: t('entry.register.passwordRequired')}]}
      >
        <Input.Password size="middle"/>
      </Form.Item>

      <Form.Item
        label={t('entry.register.confirmPassword')}
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {required: true, message: t('entry.register.confirmPasswordRequired')},
          passMatchRule,
        ]}
      >
        <Input.Password size="middle"/>
      </Form.Item>

      <Spacer height={8}/>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          {t('submit')}
        </Button>
      </Form.Item>

    </Form>
  );
}