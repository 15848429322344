import React from "react";
import {useTranslation} from "react-i18next";
import {List, Space, Typography, Alert, Spin, Row, Col} from "antd";
import {format} from 'date-fns';

import useIsMobile from 'core/hooks/useIsMobile';

import Spacer from "../Spacer";
import TimeSlot from "./TimeSlot";
import SlotLegend from "./SlotLegend";


const {Text} = Typography;

/** @namespace error.error_cd */
/** @namespace error?.error_cd */

const styles = {
  selectedDate: {
    fontSize: '19px', fontWeight: 400
  }
}

export default function SlotsView({selectedDate, data, loading, error, profile}) {

  const {t} = useTranslation();
  const {isMobile} = useIsMobile();

  return (
    <Space direction="vertical" className="w100" size={0}>
      <Text style={styles.selectedDate}>
        {`${format(selectedDate, 'MMMM d yyyy')}`}
      </Text>
      <Text>
        {t('book.selectSlot')}
      </Text>
      <Spacer height={16}/>
      <Row justify="end">
        <Col>
          <SlotLegend/>
        </Col>
      </Row>
      <Spacer height={8}/>
      {
        loading && (
          <div className="center">
            <Spin/>
          </div>
        )
      }
      {
        error?.data?.error_cd === 10211 && (
          <Alert
            message={t('book.noSlot')}
            description={t('book.noSlotsDescription')}
            type="error"
          />
        )
      }
      {
        data && (
          <List
            grid={{gutter: [20, 6], column: isMobile ? 2 : 3}}
            dataSource={data?.slots}
            renderItem={item => (
              <List.Item>
                <TimeSlot slot={item} conf={profile.configurations}/>
              </List.Item>
            )}
          />
        )
      }
    </Space>
  )
}