import React from 'react';
import {Button, Col, Form, Input, message, Row, Typography, Skeleton, notification, Modal} from 'antd';
import {useTranslation} from 'react-i18next';

import {requiredRule} from 'core/utils/FormUtils';
import useApi from 'core/hooks/useAPI';
import useFetch from 'core/hooks/useFetch';
import {getLocation, saveLocation} from 'core/apiRequests';

import Spacer from '../Spacer';

const {Title} = Typography;

/** @namespace coords.lat */
/** @namespace coords.lon */

export default function UpdateLocation() {

  const [trigger, saving] = useApi();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [, loading,] = useFetch(getLocation(), (coords) => {
    form.setFieldValue('latitude', coords.lat);
    form.setFieldValue('longitude', coords.lon);
  });

  const onFinish = (values) => {
    trigger(saveLocation(values.latitude, values.longitude))
      .then(() => {
        notification.success({
          message: t('settings.updateLocation.updateSuccess'),
          description: t('settings.updateLocation.updateSuccessDesc'),
          placement: 'bottom',
        });
      })
      .catch(err => {
        Modal.error({
          title: t('settings.updateLocation.updateFail'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  const onGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        form.setFieldValue('latitude', position?.coords?.latitude);
        form.setFieldValue('longitude', position?.coords?.longitude);
      });
    } else {
      message.error(t('settings.updateLocation.locationUnsupported'))
        .then(/* do nothing */);
    }
  };

  return (
    <div className="content">
      <Title level={3}>
        {t('settings.updateLocation.title')}
      </Title>
      <Spacer height={12}/>
      {
        loading ? <Skeleton active/> : (
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark="optional"
            form={form}>

            <Form.Item
              label={t('settings.updateLocation.latitude')}
              name="latitude"
              rules={[requiredRule(t('settings.updateLocation.validation.latitude'))]}>
              <Input size="large"/>
            </Form.Item>

            <Form.Item
              label={t('settings.updateLocation.longitude')}
              name="longitude"
              rules={[requiredRule(t('settings.updateLocation.validation.latitude'))]}>
              <Input size="large"/>
            </Form.Item>

            <Form.Item>
              <Row justify="end">
                <Col>
                  <Button type="primary" ghost size="small" onClick={onGetLocation}>
                    {t('settings.updateLocation.getCoords')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Spacer height={12}/>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={saving}>
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        )
      }
    </div>
  );
}