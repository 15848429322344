import React from 'react';
import {List, Space, Image, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import useInfiniteScroll from 'core/hooks/useInfiniteScroll';
import {previousConsultations, upcomingConsultations} from 'core/apiRequests';
import useDidMountEffect from 'core/hooks/useDidMountEffect';
import {images} from 'core/constants';

import ConsultationListItem from './ConsultationListItem';
import ConsultationListItemLoader from './ConsultationListItemLoader';
import {MediumHeading2} from '../Texts';
import {Link} from 'react-router-dom';

const {Text} = Typography;


const ConsultationsList = ({pending}) => {

  let {itemData, bottomBoundaryRef, reloadData} = useInfiniteScroll(
    pending ? upcomingConsultations() : previousConsultations()
  );

  useDidMountEffect(() => {
    reloadData(pending ? upcomingConsultations() : previousConsultations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending]);

  return (
    <div className="con-list">
      {itemData.items?.length === 0 && itemData.fetching ? (
        <List
          itemLayout="horizontal"
          dataSource={[1, 1, 1]}
          renderItem={() =>
            <ConsultationListItemLoader/>
          }
        />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={itemData.items}
          locale={{emptyText: <NoDataView pending={pending}/>}}
          renderItem={item =>
            <ConsultationListItem consultation={item} pending={pending}/>
          }
        />
      )}
      <div className="w100" style={{height: '1px'}} ref={bottomBoundaryRef}/>
    </div>
  );
};

const NoDataView = ({pending}) => {

  const {t} = useTranslation();

  return (
    <Space direction="vertical">
      <Image src={images.placeholders.noConsultations} style={{width: 144, height: 144}}/>
      <MediumHeading2 style={{color: 'black'}}>
        {t(pending ? 'consultation.noUpcoming' : 'consultation.noPrevious')}
      </MediumHeading2>
      <Text>
        {`Let's change that. `}
        <Link to="/astrologers">
          {'Find an astrologer'}
        </Link>
        {' and book a slot.'}
      </Text>

    </Space>
  );
};

export default ConsultationsList;