import React from 'react';
import {Avatar, Col, Row, Space} from 'antd';
import {VideoCameraFilled, CalendarFilled, PlusSquareFilled} from '@ant-design/icons';
import {parseISO, format} from 'date-fns';

import 'assets/styles/components/booking/MeetInfo.less';

import {bookingType, imgBaseUrl} from 'core/constants';

import {MediumHeading2, MediumHeading3, MediumHeading4} from '../Texts';
import {useTranslation} from 'react-i18next';

const dateFormat = 'cccc, dd MMMM yyyy';
const timeFormat = 'h:mm a';

export default function MeetInfo({
                                   bookType = bookingType.videoConsultation,
                                   data,
                                 }) {

  const {t} = useTranslation();

  return (
    <div className="meet-info">
      {bookType === bookingType.videoConsultation && (
        <MeetItem
          icon={<VideoCameraFilled style={{color: '#0A796B'}}/>}
          title={t('book.videoCons')}
          description={t('book.videoConsDescription')}
        />
      )}
      {bookType === bookingType.horoscopeMatching && (
        <MeetItem
          icon={<PlusSquareFilled style={{color: '#ff69b4'}}/>}
          title={t('book.submitHS')}
          description={t('book.submitHs_Desc')}
        />
      )}
      <Divider/>
      {bookType === bookingType.videoConsultation && (
        <>
          <MeetItem
            icon={<CalendarFilled style={{color: '#00000095'}}/>}
            title={format(parseISO(data.startTime), dateFormat)}
            description={`${format(parseISO(data.startTime), timeFormat)} to ${format(parseISO(data.endTime), timeFormat)}`}
          />
          <Divider/>
        </>
      )}

      <MeetItem
        icon={<RupeeIcon/>}
        title={data.rate}
      />
      <Divider/>
      <MeetConsultant
        name={data.consultant.name}
        avatarUrl={data.consultant.avatarUrl}
        category={data.consultant.category}
      />
    </div>
  );
}

function MeetItem({icon, title, description}) {
  return (
    <Row className="meet-item" wrap={false}>
      <Col className="icon" flex="none">
        {icon}
      </Col>
      <Col flex="auto">
        <Space direction="vertical" size={1}>
          <MediumHeading3>{title}</MediumHeading3>
          <MediumHeading4>{description}</MediumHeading4>
        </Space>
      </Col>
    </Row>
  );
}

const RupeeIcon = () => (
  <div className="center" style={{
    width: '20px',
    height: '20px',
    color: '#f0941e',
    fontSize: '18px',
    fontWeight: 600
  }}>
    ₹
  </div>
);

const MeetConsultant = ({avatarUrl, name, category}) => (
  <Row className="meet-item" wrap={false}>
    <Col className="icon" flex="none">
      <Avatar
        src={imgBaseUrl + avatarUrl}
        style={{width: '54px', height: '54px'}}
      />
    </Col>
    <Col flex={"auto"}>
      <Space direction="vertical" wrap={true} size={1}>
        <MediumHeading2>{name}</MediumHeading2>
        <MediumHeading4>{category?.join(', ')}</MediumHeading4>
      </Space>
    </Col>
  </Row>
);

const Divider = () => <div className="divider"/>;