import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {isBefore} from 'date-fns';

import Entry from "views/Entry";

import EntryProvider from 'core/state/EntryProvider';
import {AppContext} from 'core/state/AppProvider';

function LoginButton() {

  const {t} = useTranslation();
  const [state, dispatch] = useContext(AppContext);
  const [showOTP, setShowOTP] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("otpData");
    if (data) {
      const otpData = JSON.parse(data);
      const expiry = new Date(otpData.expiry);
      const before = isBefore(new Date(), expiry);

      if (before) {
        setShowOTP(true);
      } else {
        localStorage.removeItem("otpData");
      }
    }
  }, []);

  const onClose = () => {
    dispatch({type: 'SHOW_LOGIN', showEntry: false});
  };

  const onClick = () => {
    dispatch({type: 'SHOW_LOGIN', showEntry: true});
  };

  return (
    <>
      <Button type="primary" onClick={onClick}>
        {t('login')}
      </Button>
      <EntryProvider>
        <Entry open={state.showEntry} onClose={onClose} showOTP={showOTP}/>
      </EntryProvider>
    </>
  );
}


export default React.memo(LoginButton);