export const imgBaseUrl = 'https://api.astrog.in/image';

export const bookingType = {
  videoConsultation: 1,
  horoscopeMatching: 2
}

export const bookType = {
  BOOK: 'BOOK',
  PORUTHAM: 'PORUTHAM'
}

export const bookConsultationType = {
  VIDEO: 'VIDEO',
  MESSAGE: 'MESSAGE'
}

export const cdnImgBasePath = 'https://cdn.astrog.in/images'

export const images = {
  placeholders: {
    noConsultations: 'https://cdn.astrog.in/images/placeholders/calendar-hash.png'
  },
  icons: {
    google: 'https://cdn.astrog.in/images/icons/google.svg',
    socialMedia: {
      ig: 'https://cdn.astrog.in/images/social-media/instagram.svg',
      fb: 'https://cdn.astrog.in/images/social-media/instagram.svg',
      yt: 'https://cdn.astrog.in/images/social-media/youtube.svg'
    },
    discover: 'https://cdn.astrog.in/images/icons/ic_discover.svg'
  },
  bg: {
    sky: 'https://cdn.astrog.in/images/bg/sky-bg.png',
    wheel: 'https://cdn.astrog.in/images/bg/astro-wheel.png',
    temple: 'https://cdn.astrog.in/images/bg/img-banner.jpg',
    dailyHS: 'https://cdn.astrog.in/images/daily-horoscope/star-bg.svg'
  },
  working: {
    step1: 'https://cdn.astrog.in/images/working/working-step1.svg',
    step2: 'https://cdn.astrog.in/images/working/working-step2.svg',
    step3: 'https://cdn.astrog.in/images/working/working-step3.svg',
    step4: 'https://cdn.astrog.in/images/working/working-step4.svg',
    step5: 'https://cdn.astrog.in/images/working/working-step5.svg',
  }
}