import {Col, Progress, Rate, Row, Space, Typography} from 'antd';
import React from 'react';
import useIsMobile from 'core/hooks/useIsMobile';

const {Title, Text} = Typography;

const styles = {
  star: {
    fontSize: '16px'
  }
}

export default function FeedbackScore({score}) {

  const {isMobile} = useIsMobile();

  return (
    <Row gutter={isMobile ? 8 : 36}>
      <Col>
        <Space direction="vertical">
          <Title>
            {score.score}
          </Title>
          <Rate
            allowHalf
            defaultValue={score.score}
            disabled style={styles.star}
          />
        </Space>
      </Col>
      <Col flex="auto">
        <ScoreCount noOfStars={5} score={score}/>
        <ScoreCount noOfStars={4} score={score}/>
        <ScoreCount noOfStars={3} score={score}/>
        <ScoreCount noOfStars={2} score={score}/>
        <ScoreCount noOfStars={1} score={score}/>
      </Col>
    </Row>
  )
}

function ScoreCount({noOfStars, score}) {

  const {isMobile} = useIsMobile();

  return (
    <Row justify="end" align="middle" gutter={16}>
      <Col style={{width: isMobile ? '54px' : '128px', marginLeft: 'auto'}}>
        <Row justify="end">
          <Col>
            <Space>
              {isMobile && <Text>{noOfStars}</Text>}
              <Rate
                allowHalf
                count={isMobile ? 1 : noOfStars}
                defaultValue={isMobile ? 1 : noOfStars}
                disabled
                style={styles.star}
              />
            </Space>
          </Col>
        </Row>
      </Col>
      <Col flex="auto">
        <Progress
          strokeColor="#5cb85c"
          showInfo={false}
          strokeLinecap="butt"
          percent={getProgress(score.detail, score.count, noOfStars)}
        />
      </Col>
    </Row>
  )
}

const getProgress = (ratings, totalRatings, numberOfStars) => {
  let rating = ratings?.find(r => r.rating === numberOfStars);
  if (!rating)
    return 0;
  return (rating.count / totalRatings) * 100;
};