import React from "react";
import {Col, Image, Row, Space, Typography, Popover} from "antd";

import DashboardItem from "./DashboardItem";
import Spacer from "../Spacer";

import {dailyPredictions} from "core/apiRequests";
import useFetch from "core/hooks/useFetch";
import useIsMobile from "core/hooks/useIsMobile";
import {cdnImgBasePath, images} from 'core/constants';

/** @namespace star.luckyNumber **/
/** @namespace star.luckyTime **/
/** @namespace star.mood **/
/** @namespace star.compatibility **/
/** @namespace star.color **/
/** @namespace star.dateRange **/

const {Text, Title} = Typography;

const stars = [
  {imgName: 'taurus', starName: 'Taurus'},
  {imgName: 'libra', starName: 'Libra'},
  {imgName: 'leo', starName: 'Leo'},
  {imgName: 'aries', starName: 'Aries'},
  {imgName: 'aquarius', starName: 'Aquarius'},
  {imgName: 'cancer', starName: 'Cancer'},
  {imgName: 'capricorn', starName: 'Capricorn'},
  {imgName: 'gemini', starName: 'Gemini'},
  {imgName: 'scorpio', starName: 'Scorpio'},
  {imgName: 'sagittarius', starName: 'Sagittarius'},
  {imgName: 'pisces', starName: 'Pisces'},
  {imgName: 'virgo', starName: 'Virgo'},
];

function DailyHoroscopes() {

  const [data,] = useFetch(dailyPredictions);
  const {isMobile} = useIsMobile();

  const toHoroscopeStar = (item, key) => (
    <Col key={key} span={isMobile ? 12 : 6}>
      <HoroscopeStar item={item} data={data}/>
    </Col>
  )

  return (
    <div className="daily-horoscopes">
      <DashboardItem
        title="dashboard.dailyHoroscopes.title"
        subtitle="dashboard.dailyHoroscopes.desc"
      >
        <Row gutter={[24, isMobile ? 24 : 48]}>
          {stars.map(toHoroscopeStar)}
        </Row>
      </DashboardItem>
    </div>
  )
}

const HoroscopeDetail = ({item, star}) => {

  return (
    <Space align="start" style={{maxWidth: '384px'}}>
      <Image
        preview={false}
        src={`${cdnImgBasePath}/daily-horoscope/${item.imgName}.svg`}
        width={100}
        height={100}
      />
      <Space direction="vertical" size={0}>
        {star?.description}
        <Spacer height={12}/>
        {horoscopeItem('Lucky Number', star?.luckyNumber)}
        {horoscopeItem('Lucky Time', star?.luckyTime)}
        {horoscopeItem('Mood', star?.mood)}
        {horoscopeItem('Compatibility', star?.compatibility)}
        {horoscopeItem('Color', star?.color)}
      </Space>
    </Space>
  );
}


const HoroscopeStar = ({item, data}) => {

  let theStar;

  if (data) {
    theStar = data.find(el => el.sign.toLowerCase() === item.starName.toLowerCase())
  }
  const date = theStar?.dateRange;

  return (
    <Popover
      content={
        <HoroscopeDetail item={item} star={theStar}/>
      }
      title={
        <Title level={5}>
          {`${item.starName}${date ? ` (${date})` : ''}`}
        </Title>
      }
    >
      <Space direction="vertical" align="center" style={itemStyle}>
        <Image
          src={ `${cdnImgBasePath}/daily-horoscope/${item.imgName}.svg`}
          width={100}
          height={100}
          preview={false}
        />
        <Text className="primary-color">{item.starName}</Text>
        <Text type="secondary">{theStar?.dateRange ?? ''}</Text>
      </Space>
    </Popover>
  );
};

const horoscopeItem = (title, value) => (
  <div>
    <Text type="secondary">{`${title}: `}</Text>
    <Text strong>{value}</Text>
  </div>
)

const itemStyle = {
  background: `url("${images.bg.dailyHS}") no-repeat`,
  backgroundSize: '100% 100%',
  width: '100%',
  padding: '24px',
  cursor: 'pointer'
}

export default DailyHoroscopes;