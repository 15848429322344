import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Col, Row, Tabs, Alert, Form, Input, Button} from 'antd';

import useFetch from 'core/hooks/useFetch';
import {getSlot} from 'core/apiRequests';
import useIsMobile from 'core/hooks/useIsMobile';
import useBookConsultation from 'core/hooks/consultation/useBookConsultation';
import {bookConsultationType, bookType} from 'core/constants';

import Container from 'components/Container';
import Header from 'components/booking/Header';
import Spacer from 'components/Spacer';
import MeetInfo from 'components/booking/MeetInfo';
import {DatePicker} from 'components/Pickers';
import ConsultationDetailLoading from 'components/consultations/ConsultationDetailLoading';
import UnknownError from 'components/UnknownError';

/** @namespace slot.consultant.name */

export default function ConfirmSlot() {

  const {id} = useParams();
  const [slot, loading, error, refreshSlots] = useFetch(getSlot(id));
  const {isMobile} = useIsMobile();

  if (loading) {
    return <ConsultationDetailLoading/>;
  }

  if (error) {
    return (
      <UnknownError
        onRetryClick={() => refreshSlots()}
      />
    );
  }

  return (
    <Container className="main-pv">
      <Header
        avatarUrl={slot.consultant.avatarUrl}
        name={slot.consultant.name}
        title={'book.confirmBook'}
      />
      <Spacer height={20}/>
      <Row gutter={{xs: 0, sm: 64}}>
        <Col xs={24} sm={11}>
          <MeetInfo data={slot}/>
        </Col>
        {isMobile && (
          <Col span={24}>
            <Spacer height={24}/>
          </Col>
        )}
        <Col xs={24} sm={10}>
          <Tabs items={[{label: 'Additional Info', key: 'item-1'}]}/>
          <Spacer height={12}/>
          <BookingForm slot={slot}/>
        </Col>
      </Row>
      <Spacer height={12}/>
    </Container>
  );
}

const BookingForm = ({slot}) => {

  const [booking, bookSlot] = useBookConsultation(bookType.BOOK, bookConsultationType.VIDEO);
  const {t} = useTranslation();

  const onFinish = (values) => {
    bookSlot({
      ...values,
      slotId: slot.id,
      consultantId: slot.consultant.id,
    });
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark="null"
    >
      <Form.Item name="dob" label={t('book.dobLabel')}>
        <DatePicker showTime size="large"/>
      </Form.Item>

      <Form.Item name="location" label={t('book.placeLabel')}>
        <Input size="large"/>
      </Form.Item>

      <Form.Item name="note" label={t('book.leaveNote')}>
        <Input.TextArea rows={4}/>
      </Form.Item>

      <Alert message={t('book.optionalHint')} type="info"/>

      <Spacer height={32}/>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={booking}>
          {t('book.bookSlot')}
        </Button>
      </Form.Item>
    </Form>
  );
};