import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Input, Modal, Typography} from 'antd';

import useApi from 'core/hooks/useAPI';
import {EntryContext} from 'core/state/EntryProvider';
import {signUp} from 'core/apiRequests';

import {SHOW_LOGIN, SHOW_SIGN_UP_OTP} from 'views/Entry';

import Spacer from 'components/Spacer';

import SelectPhoneCode from '../InputPhone';
import {addMinutes} from 'date-fns';


const {Text, Link} = Typography;

export default function SignUp() {

  const [state, setState] = useContext(EntryContext);
  const {t} = useTranslation();
  const [trigger, loading] = useApi();

  const gotoLogin = () => setState({
    ...state,
    current: SHOW_LOGIN
  });

  const onFinish = (values) => {
    trigger(signUp(values))
      .then(() => {
        const otpData = {
          ...values,
          expiry: addMinutes(new Date(), 5)
        }
        localStorage.setItem("otpData", JSON.stringify(otpData));

        setState({
            ...state,
            email: values.email,
            mobile: values.mobile,
            countryCode: values.countryCode,
            current: SHOW_SIGN_UP_OTP,
          }
        );
      })
      .catch(err => {
        Modal.error({
          title: t('entry.error.signUpFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  const passMatchRule = ({getFieldValue}) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('entry.register.passNotMatch')));
    },
  });

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark="optional"
    >

      <Form.Item
        label={t('entry.register.name')}
        name="name"
        rules={[{required: true, message: t('entry.register.nameRequired')}]}
      >
        <Input size="middle"/>
      </Form.Item>

      <Form.Item
        label={t('entry.register.email')}
        name="email"
        rules={[{required: true, message: t('entry.register.emailRequired')}]}
      >
        <Input size="middle"/>
      </Form.Item>

      <Form.Item
        label={t('entry.register.mobile')}
        name="mobile"
        rules={[{required: true, message: t('entry.register.mobileRequired')}]}
      >
        <Input
          size="middle"
          addonBefore={(
            <Form.Item name="countryCode" noStyle rules={[{required: true, message: ''}]}>
              {SelectPhoneCode}
            </Form.Item>
          )}
        />
      </Form.Item>

      <Form.Item
        label={t('entry.register.password')}
        name="password"
        rules={[{required: true, message: t('entry.register.passwordRequired')}]}
      >
        <Input.Password size="middle"/>
      </Form.Item>

      <Form.Item
        label={t('entry.register.confirmPassword')}
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {required: true, message: t('entry.register.confirmPasswordRequired')},
          passMatchRule,
        ]}
      >
        <Input.Password size="middle"/>
      </Form.Item>

      <Text className="tos">
        {'By singing up, you agree to the '}
        <Link href="https://www.astrog.in/legal-and-privacy" target="_blank">
          {'terms and services'}
        </Link>
        {' of AstroG.'}
      </Text>

      <Spacer height={8}/>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          {t('submit')}
        </Button>
      </Form.Item>

      <Button type="link" onClick={gotoLogin}>
        {t('entry.gotoLogin')}
      </Button>

    </Form>
  );
}
