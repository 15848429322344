import React from 'react';
import {Skeleton} from "antd";

import useFetch from "core/hooks/useFetch";
import useInfiniteScroll from "core/hooks/useInfiniteScroll";
import {ratings, reviews} from "core/apiRequests";

import "components/astrologer-profile/Feedbacks.less";
import FeedbackScore from "components/astrologer-profile/FeedbackScore";
import FeedbackReviews from "./FeedbackReviews";
import Spacer from "../Spacer";
import UnknownError from "../UnknownError";

export default function Feedbacks({userId}) {

  const [score, loading, error, reload] = useFetch(ratings(userId));
  const {itemData, bottomBoundaryRef, reloadData} = useInfiniteScroll(reviews(userId));

  if (loading) {
    return <Skeleton active className="mt-4"/>
  }

  if (error) {
    return (
      <UnknownError onRetryClick={() => {
        reload(ratings(userId)); // reload scores
        reloadData(reviews(userId)); // reload reviews
      }}/>
    )
  }

  return (
    <div className="mt-3 feedbacks">
      <FeedbackScore score={score}/>
      <Spacer height={20}/>
      {
        itemData.items && <FeedbackReviews reviews={itemData.items}/>
      }
      <div className="page-boundary" ref={bottomBoundaryRef}/>
    </div>
  )
}

