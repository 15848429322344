import {useState, createContext} from "react";

const initialState = {
  current: 1 // SHOW
}

export const EntryContext = createContext(initialState);

const EntryProvider = (props) => {

  const [state, setState] = useState(initialState);

  return (
    <EntryContext.Provider value={[state, setState]}>
      {props.children}
    </EntryContext.Provider>
  );
};

export default EntryProvider;
