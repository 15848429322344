import Container from '../components/Container';
import {Collapse} from 'antd';
import {useTranslation} from 'react-i18next';

const {Panel} = Collapse;

const FAQ = () => {

  const {t} = useTranslation();

  return (
    <Container className="main-pv">
      <h2 className="mb-4 text-center">
        Frequently asked questions
      </h2>

      <Collapse defaultActiveKey={['0']}>
        {Array(12)
          .fill(0)
          .map((value, index) => {
              const qNo = index + 1
              return (
                <Panel header={t(`faq.q${qNo}.q`)} key={index}>
                  <td dangerouslySetInnerHTML={{__html: t(`faq.q${qNo}.ans`)}} />
                </Panel>
              );
            }
          )
        }
      </Collapse>
    </Container>
  );
};

export default FAQ;