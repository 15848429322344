import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Row, Col} from 'antd';
import {format} from 'date-fns';

import Container from 'components/Container';
import SlotsView from "components/booking/SlotsView";
import WebCalendar from 'components/booking/WebCalendar';
import Spacer from 'components/Spacer';
import Header from 'components/booking/Header';
import ConsultationDetailLoading from 'components/consultations/ConsultationDetailLoading';
import UnknownError from 'components/UnknownError';
import MobileCalendar from 'components/booking/MobileCalendar';

import useFetch from 'core/hooks/useFetch';
import {anAstrologer, getSlots} from 'core/apiRequests';
import useIsMobile from 'core/hooks/useIsMobile';


const apiFormat = (date) => format(date, 'yyyy-MM-dd');

/** @namespace slotData.slots */
/** @namespace profile.profile.configurations */

export default function ChooseSlot() {

  const {id} = useParams();
  const [slotData, slotsLoading, slotError, refreshData] = useFetch(getSlots(id, apiFormat(new Date())));
  const [profile, profileLoading, profileError, refreshProfile] = useFetch(anAstrologer(id));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {isMobile} = useIsMobile();

  const onDateChange = (date) => {
    setSelectedDate(date);
    refreshData(getSlots(id, apiFormat(date)));
  };


  if (profileLoading) {
    return <ConsultationDetailLoading/>;
  }

  if (profileError) {
    return (
      <UnknownError
        onRetryClick={() => refreshProfile()}
      />
    );
  }

  return (
    <Container className="main-pv">
      <Header
        avatarUrl={profile.avatarUrl}
        name={profile.name}
        title={'book.bookAppointment'}
      />
      <Spacer height={12}/>
      <Row gutter={[{xs: 0, sm: 64}, 16]}>
        <Col xs={24} sm={10}>
          {isMobile ? (
            <MobileCalendar onChange={onDateChange}/>
          ) : (
            <WebCalendar onDateChange={onDateChange}/>
          )}
        </Col>
        <Col xs={24} sm={14} style={{minHeight: '500px'}}>
          <SlotsView
            selectedDate={selectedDate}
            profile={profile}
            data={slotData}
            loading={slotsLoading}
            error={slotError}
          />
        </Col>
      </Row>
    </Container>
  );
}

