import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Space, Alert, Typography, Button, Modal} from 'antd';

import useFetch from 'core/hooks/useFetch';
import {abortBooking, bookRetry, bookVerify} from 'core/apiRequests';
import useQueryParams from 'core/hooks/useQueryParams';
import useIsMobile from 'core/hooks/useIsMobile';
import useCanJoin from 'core/hooks/consultation/useCanJoin';
import useJoinMeet from 'core/hooks/consultation/useJoinMeet';
import useApi from 'core/hooks/useAPI';
import {bookingType} from 'core/constants';

import Container from 'components/Container';
import ConsultationDetailLoading from 'components/consultations/ConsultationDetailLoading';
import UnknownError from 'components/UnknownError';
import Header from 'components/booking/Header';
import Spacer from 'components/Spacer';
import MeetInfo from 'components/booking/MeetInfo';

const {Text, Title} = Typography;

const PaymentStatus = () => {

  let {order_id, order_token} = useQueryParams();
  const [data, loading, error, refresh] = useFetch(bookVerify(order_id, order_token));
  const {t} = useTranslation();
  const {isMobile} = useIsMobile();

  if (loading) {
    return <ConsultationDetailLoading/>;
  }

  if (error) {
    if (error.data.error_cd === 10906) {
      return (
        <PaymentError
          orderId={order_id}
        />
      );
    }

    return (
      <UnknownError
        onRetryClick={() => refresh()}
      />
    );
  }

  return (
    <Container className="main-pv">
      <Header
        title={data.type !== "PORUTHAM" ? t('book.success.title') : t('book.success.poruthamSuccess')}
        name={data.consultant.name}
        avatarUrl={data.consultant.avatarUrl}
      />
      <Spacer height={20}/>
      <Row gutter={{xs: 0, sm: 44}}>
        <Col xs={24} sm={11}>
          <MeetInfo
            data={data}
            bookType={data.type !== "PORUTHAM" ? bookingType.videoConsultation : bookingType.horoscopeMatching}
          />
        </Col>
        {isMobile && (
          <Col span={24}>
            <Spacer height={44}/>
          </Col>
        )}
        <Col xs={24} sm={13}>
          {data.type !== "PORUTHAM" && <VideoBookingSuccess details={data}/>}
          {data.type === "PORUTHAM" && <PoruthamBookingSuccess details={data}/>}
        </Col>
      </Row>
    </Container>
  );
};

const VideoBookingSuccess = ({details}) => {

  const [canJoin] = useCanJoin(details);
  const [joining, joinMeet] = useJoinMeet(details.sessionId);
  const {t} = useTranslation();

  return (
    <Space direction="vertical" className="w100">
      {
        canJoin ? (
          <>
            <Title level={4} type="success">
              {t('book.success.now_line1')}
            </Title>
            <Text>
              {t('book.success.now_line2')}
            </Text>
            <Button className="action-btn" size="large" disabled={!canJoin || joining} onClick={joinMeet}>
              {t('consultation.join')}
            </Button>
          </>
        ) : (
          <>
            <Title level={4} type="success">
              {t('book.success.later_line1')}
            </Title>
            <Text>
              You can join the consultation from the <Link
              to="/consultations/upcoming">{'My Consultations'}</Link> page.
            </Text>
            <Text>
              {t('book.success.later_line3')}
            </Text>
          </>
        )
      }
      <Spacer height={4}/>
      <Alert
        description={t('book.success.fastNet')}
      />
    </Space>
  );
};

const PoruthamBookingSuccess = () => {
  const {t} = useTranslation();

  return (
    <Space direction="vertical" className="w100">
      <Title level={4} type="success">
        {t('book.success.porutham_line1')}
      </Title>
      <Text>
        {t('book.success.porutham_line2')}
      </Text>
      <Text>
        Once the astrologer responds, you may visit the
        <Link to="/consultations/upcoming">{' My Consultations '}</Link>
        page to view the result.
      </Text>
    </Space>
  );
};

const PaymentError = ({orderId}) => {

  const [triggerRetry, retrying] = useApi();
  const [triggerAbort, aborting] = useApi();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const onRetry = () => {
    triggerRetry(bookRetry(orderId))
      .then(data => {
        window.location.href = data.paymentLink;
      })
      .catch(err => {
        Modal.error({
          title: t('book.error.retryFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  const onAbort = () => {
    triggerAbort(abortBooking(orderId))
      .then(() => navigate('/astrologers'))
      .catch(err => {
        Modal.error({
          title: t('book.error.abortFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  return (
    <Container className="main-pv">
      <Title level={4}>
        {t('book.error.shouldRetry')}
      </Title>
      <Spacer height={16}/>
      <Space size="large">
        <Button onClick={onAbort} loading={aborting} size="large" style={btnStyle}>
          {t('abort')}
        </Button>
        <Button onClick={onRetry} loading={retrying} type="primary" size="large" style={btnStyle}>
          {t('retry')}
        </Button>
      </Space>
    </Container>
  );
};

const btnStyle = {
  width: '128px'
};

export default PaymentStatus;