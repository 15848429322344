import React from 'react';
import {Result, Button} from 'antd';
import {withTranslation} from 'react-i18next';

import {logError} from 'core/apiRequests';
import ApiService from 'core/services/ApiService';

import Container from './Container';
import {withRouter} from './withRouter';

class AppErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {hasError: false};

    const {history} = this.props;

    this.toHome = this.toHome.bind(this);

    history?.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  toHome() {
    this.props.navigate('/');
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});

    ApiService.exchange(logError(error, info))
      .then(() => {
        // do nothing
      });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container className="main-pv">
          <Result
            status="500"
            title={this.props.t('unknownErrorShort')}
            subTitle={this.props.t('unknownErrorDesc')}
            extra={
              <Button type="primary" onClick={this.toHome}>
                {this.props.t('backHome')}
              </Button>}
          />
        </Container>
      );
    }
    return this.props.children;
  }

}

export default withTranslation()(withRouter(AppErrorBoundary));
