import Container from '../components/Container';

const Privacy = () => {
  return (
    <Container className="main-pv">
      <h2 className="text-center">AstroG Website Terms &amp; Conditions</h2>
      <p className="p2">&nbsp;</p>
      <p className="p3"><span className="s1"><strong>Use of AstroG Website/AstroG Services</strong></span></p>
      <p className="p3"><span className="s1">Please read the following terms and conditions carefully before registering on, accessing, browsing, downloading or using the AstroG website. These terms of use constitute a legally binding agreement between you and the Company, Neram Technologies Private Limited (having its registered address 130 Akkoor 2, Sakthikulangara, Kollam, Kerala-691581) regarding your use of the web site i.e https://astrog.in (the Site) and any services offered by the company including but not limited to delivery of content via the Site, any mobile or internet connected device or otherwise (the &ldquo;the Service&rdquo;). &ldquo;User&rdquo; or &ldquo;You&rdquo; means any person who access or avail this site of the Company for the purpose of consultation, sharing, transacting, displaying or uploading information, personal data or views and includes other persons jointly participating in using the site of the Company.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Acceptance</strong></span></p>
      <p className="p3"><span className="s1">By registering, accessing<span
        className="Apple-converted-space">&nbsp; </span>or browsing the Site or Service, you automatically and immediately agree to be bound by these Terms. You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and is capable of entering, performing and adhering to these Terms and that you agree to be bound by the following terms and conditions.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Eligibility</strong></span></p>
      <p className="p3"><span className="s1">The AstroG service is eligible for any persons who is above 18 years of age, or to anyone previously suspended or removed by AstroG from availing the AstroG service or accessing the AstroG platform. While individuals under the age of 18 may utilize the Service of the site, they shall do so only with the involvement &amp; guidance of their parents and / or legal guardians, under such Parent /Legal guardian&rsquo;s registered account. </span>
      </p>
      <p className="p3"><span className="s1"><strong>Data Privacy</strong></span></p>
      <p className="p3"><span className="s1">You agree to register prior to uploading any data and / or comment and any other use or services of this site and provide your details including but not limited to complete name, age, date of birth, time of birth, place of birth, other related information required for astrological consultations, email address, residential address, contact number, geo location, browser information. We do not collect any payment instrument details. This is solely handled by our payment partner which is a ISO/IEC 27001:2013 certified payment company, and these details may be stored by our payment partners if you choose to and are guided by the terms and conditions of respective payment service providers. </span>
      </p>
      <p className="p3"><span className="s1">Unless otherwise stated, copyright and all intellectual property rights in all material presented on the site (including but not limited to text, audio, video or graphical images), trademarks and logos appearing on this site are the property of Neram Technologies Private Limited and are protected under applicable Indian laws. You agree not to use any framing techniques to enclose any trademark or logo or other proprietary information of https://www.astrog.in; or remove, conceal or obliterate any copyright or other proprietary notice or any credit-line or dateline on other mark or source identifier included on the Site/Service, including without limitation, the size, colour, location or style of https://www.astrog.in marks. Any infringement shall be vigorously defended and pursued to the fullest extent permitted by law.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Charges</strong></span></p>
      <p className="p3"><span className="s1">Company reserves the right to charge consultation from a user. Astrologers have the flexibility of choosing the rate for the consultations they provide, and can change the rates according to the demand, essentially rates for slots may differ from time to time. You can check the rates before booking. To speak with a Astrologer user may need to pay for the consultation in advance. </span>
      </p>
      <p className="p3"><span className="s1"><strong>Refund and Cancellation Policy</strong></span></p>
      <p className="p3"><span className="s1">In the event, the Astrologer with whom User has booked an appointment, has not been able to meet the User, User needs to write to us at <a
        href="mailto:support@astrog.in"><span className="s2">support@astrog.in</span></a> within five days of the event; in which case the entire consultation amount as mentioned on the website will be refunded to the User.</span>
      </p>
      <p className="p3"><span className="s1">In the event where a user has booked an appointment and failed to join the call, the User is not entitled for refund.</span>
      </p>
      <p className="p3"><span className="s1">In the event, the Astrologer with whom User has booked an appointment, has cancelled an appointment later the entire consultation amount as mentioned on the website will be refunded to the User. AstroG has no liability if an appointment is cancelled by the Astrologer.</span>
      </p>
      <p className="p3"><span className="s1">If the user wishes to cancel a booking, the User needs to write to us specifying the reason for cancellation. The user will be refunded the amount after levying a cancellation charges as detailed below:</span>
      </p>
      <ol className="ol1">
        <li className="li3"><span className="s1">No cancellation charges will be levied if cancelled before 24 hours of the appointment start time.</span>
        </li>
        <li className="li3"><span className="s1">A cancellation charge of Rs. 20 will be levied if cancelled within 24 hours of the appointment start time.</span>
        </li>
      </ol>
      <p className="p3"><span className="s1">Users will not be entitled for any refunds in cases where, the Astrologer is unable to meet the User at the exact time of the scheduled appointment time and the User is required to wait, irrespective of the fact whether the User is required to wait or choose to not obtain the services from the said Astrologer.</span>
      </p>
      <p className="p3"><span className="s1">Your refund will be processed within 7 working days.</span></p>
      {/*<p className="p2">&nbsp;</p>*/}
      <p className="p3"><span className="s1"><strong>Other Terms and Conditions </strong></span></p>
      <p className="p3"><span className="s1">Company may add to or change or update these Terms of Use, from time to time entirely at its own discretion. You are responsible for checking these Terms of Use periodically to remain in compliance with these terms. Your use of a Site after any amendment to the Terms of Use shall constitute your acceptance of these terms and you also agree to be bound by any such changes/revisions.</span>
      </p>
      <p className="p3"><span className="s1">Company grants you permission to only access and make personal use of the Site and you agree not to, directly or indirectly, download or modify/alter/change/amend/vary/transform/revise/ translate/ copy/ publish/ distribute or otherwise disseminate https://www.astrog.in Site/Service, or any portion of it. You are permitted to print or download extracts from these pages for your personal, non-commercial use only. Any copies of these pages saved to disk or to any other storage medium may only be used for subsequent viewing purposes or to print extracts for personal use.</span>
      </p>
      <p className="p3"><span className="s1">Company forbids you from any attempts to resell or put to commercial use any part of the Site; any collection and use of any information/content; any derivative use of the Site or its contents; any downloading or copying of account information for the benefit of any third party; or any data gathering or extraction tools; or any use of meta tags. You may not (whether directly or through the use of any software program) create a database in electronic or structured manual form by regularly or systematically downloading and storing all or any part of the content/information/pages from this Site.</span>
      </p>
      <p className="p3"><span className="s1">No part of the Site may be reproduced or transmitted to or stored in any other website, nor shall any of its pages or part thereof be disseminated in any electronic or non-electronic form, nor included in any public or private electronic retrieval system or service without prior written permission. Requests to republish https://www.astrog.in material for distribution should be addressed to https://www.astrog.in at email:support@astrog.in</span>
      </p>
      <p className="p3"><span className="s1">The content provided is for information / consultation purposes alone and does not substitute for specific advice. https://www.astrog.in disclaims all liability for damages caused by use of content on the site. Any content/information provided on the website on an astrologer profile are managed by the astrologer. https://www.astrog.in has exercisedexercises due diligence in checking the correctness and authenticity of the information contained in the site from time to time, but https://www.astrog.in or any of its affiliates or anyone attached to it shall not in anyway be responsible for any loss or damage that may arise to any person from any inadvertent error in the information contained in any channel, or service on the site.</span>
      </p>
      <p className="p3"><span className="s1">The information from or through this site is provided "AS IS " or "AS AVAILABLE", and all warranties expressed or implied of any kind, regarding any matter pertaining to any service or channel, including without limitation the implied warranties of merchantability, fitness for a particular purpose, and non-infringement are disclaimed.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in and its affiliates and associates shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorised access to, alteration of, or use of information, sService contained on the site.</span>
      </p>
      <p className="p3"><span className="s1">All contents including text, graphics, design and other works and materials that are made available to view and/or download, excluding content that may be made available by the users or visitors through the services, is owned by and is the copyrighted work of https://www.astrog.in unless otherwise indicated and is protected by copyright laws.</span>
      </p>
      <p className="p3"><span className="s1">Contents on this site, including but not limited to text, software, music, sound, photographs, video, graphics or other material contained in either sponsor advertisements, or Internet-distributed, commercially produced information presented to user by the services by https://www.astrog.in and its affiliates, advertisers and partners, or other https://www.astrog.in users may be protected by copyrights, trademarks, patents or other proprietary rights and laws; therefore, you are only permitted to use this protected content as expressly authorized by the owner of the content, the services or the advertiser. These materials cannot be modified, copied, reproduced, republished, uploaded, posted, transmitted or distributed in any way for non-personal use or without obtaining the prior permission from https://www.astrog.in No content or information on the site may be transferred, sold or derivatives made for commercial use.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in disclaim all responsibility and liability for any damages caused by downloading of any data.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in reserves the right to make modifications and alterations in the information contained on the site without notice. https://www.astrog.in also reserves the right to modify, suspend/cancel, or discontinue any or all channels, or service at any time without notice.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in reserves the right to modify the Terms of Use of any service or channel without any liability. https://www.astrog.in reserves the right to take all measures necessary to prevent access to any service or channel or termination of service in its sole discretion without notice including if the Terms of Use are not complied with or are contravened or there is any violation of copyright, trademark or other proprietary rights.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in endeavours to provide a web platform for people to interact and exchange personal problems with Astrologers, Numerologist, etc. This is a service offered with the help of astrology, has not demonstrated it's effectiveness in controlled studies and has no scientific validity, and is thus regarded as pseudoscience. Astrology consists of a number of belief systems that hold that there is a relationship between astronomical phenomena and events or descriptions of personality in human world. Therefore, the accuracy, integrity or quality of such content cannot be guaranteed. You understand that by using such services, you may be exposed to objectionable matter. By using the facility of audio, video chat and other community services you agree not to upload, post, or otherwise transmit any matter or views, which are, defamatory, abusive, pervasive, obscene, of Prurient interest, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable. You may also not indulge in cyber stalking, become or create a nuisance for any visitor / user of the Site.</span>
      </p>
      <p className="p3"><span className="s1">You may not send, submit, post, or otherwise transmit, material or messages that contain software virus, or any other files that are designed to interrupt, destroy, and negatively affect in any manner whatsoever, any electronic equipment in connection with the use of this Site, or other user's ability to engage in real time exchanges.</span>
      </p>
      <p className="p3"><span className="s1">Under no circumstances will company be held responsible or liable, in any way, for any content which in legal opinion / astrologers opinion and/or other user's opinion is derogatory, threatening, lewd, lascivious, filthy, excessively violent, defamatory, obscene or offensive or offends public sensibilities or morals, etc and will company be held responsible or liable, in any way, for any content which in Legal opinion is derogatory, threatening, defamatory, obscene or offensive or offends public sensibilities or morals and shall also not assume liability for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the consultation with astrologers, numerologist etc through this Site, or any infringement of another's rights, including intellectual property rights. You specifically agree that company is not responsible for any content sent using and/or included in www.astrog.in site/service by any third party.</span>
      </p>
      <p className="p3"><span className="s1">However, Neram Technologies Private Limited reserve the right, but have no obligation, to monitor interactions between you and consultant through the Site and take any other action in good faith to restrict access to or the availability of any material / content/ communication that it or another user of the may consider to be derogatory, threatening, lewd, lascivious, filthy, excessively violent, defamatory, obscene, offensive, offends public sensibilities or morals or otherwise objectionable. In addition, company may in its sole and unfettered discretion, to control the communication between user and consultant of the site www.astrog.in</span>
      </p>
      <p className="p3"><span className="s1">www.astrog.in may send information and offer products and services to you from time to time. Notwithstanding the foregoing, https://www.astrog.in reserves the right to disclose any information in response to / that it is required to be shared, disclosed or make made available to any governmental, administrative, regulatory or judicial authority under any law or regulation applicable to www.astrog.in</span>
      </p>
      <p className="p3"><span className="s1">Further, https://www.astrog.in can (and you authorize https://www.astrog.in to) disclose your name, street address, city, state, zip code, country, phone number, email, and company name to Intellectual Property right's owners, as we in our sole discretion believe necessary or appropriate in connection with an investigation of fraud, intellectual property infringement, piracy, or other unlawful activity.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Warranties</strong></span></p>
      <p className="p3"><span className="s1">No representations, warranties or guarantees whatsoever are made as to the accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in or its affiliates or associates or anyone attached to it do not provide any judgment or warranty in respect of the authenticity or correctness of the content of other services or sites to which links are provided. https://www.astrog.in is no way responsible for mistakes or failure of performance of any such sites or services.</span>
      </p>
      <p className="p3"><span className="s1">A link to another service or site is not an endorsement of any products or services on such site or the site. astrog.in is not responsible for the availability of these external sites or resources.</span>
      </p>
      <p className="p3"><span className="s1"><strong>Indemnification</strong></span></p>
      <p className="p3"><span className="s1">You agree to defend, indemnify and hold <a
        href="http://www.astrog.in"><span className="s2">www.astrog.in</span></a> and its personals harmless from any claim, demand, action, damage, loss, cost or expense, including without limitation, reasonable attorney&rsquo;s fees, incurred in connection with any suit or proceeding brought against www.astrog.in arising out of your use of the Site or alleging facts or circumstances that could constitute a breach of any provision of these terms by you. If you are obligated to indemnify https://www.astrog.in, https://www.astrog.in will have the right, in its sole and unfettered discretion, to control any action or proceeding and determine whether www.astrog.in wishes to settle it, and if so, on what terms.</span>
      </p>
      <p className="p3"><span className="s1">None of the provisions of the User Agreement shall be deemed to constitute a partnership or agency between you and www.astrog.in and you shall have no authority to bind https://www.astrog.in in any manner, whatsoever.</span>
      </p>
      <p className="p3"><span className="s1">https://www.astrog.in shall have no liability to you for any interruption or delay, to access the Site irrespective of the cause.</span>
      </p>
      <p className="p3"><span className="s1">All legal proceedings shall be subject to the jurisdiction of the courts in Kollam, Kerala and shall be governed by and construed in accordance with the laws of India.</span>
      </p>
      <p className="p3"><span className="s1">These terms of service constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</span>
      </p>
      <p className="p3"><span className="s1">We reserve the right to remove any messages we deem to be inappropriate, i.e., racist, sexist or threatening. Messages using inappropriate language will also be removed. We do not wish to be censors, but our forums must remain civilized and respect the rights of others.</span>
      </p>
      <p className="p3"><span className="s1">You and https://www.astrog.in agree that any cause of action arising out of or related to the www.astrog.in web site, only, must commence within one (1) year after the cause of action accrues otherwise, such cause of action will be permanently barred.</span>
      </p>
      <p className="p3"><span className="s1">By using https://www.astrog.in site and services, you signify your acceptance of this Privacy Statement. If you do not agree or are not comfortable with any policy described in this Privacy statement, your only remedy is to discontinue use of astrog.in site. We reserve the right, to modify this Privacy Statement at any time.</span>
      </p>
      <p className="p3"><span className="s1">Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed through email to support@astrog.in</span>
      </p>
      <p className="p3"><span className="s1">All notices shall be sent in english language only.</span></p>
      <p className="p3"><span className="s1">This disclaimer/terms of service notification is subject to change without notice. To make sure that you are aware of any changes, kindly review the policy periodically.</span>
      </p>

    </Container>
  );
};

export default Privacy;