const BROWSER = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  twitter: /\bTwitter/i,
  line: /\bLine\//i,
  wechat: /\bMicroMessenger\//i,
  puffin: /\bPuffin/i,
  miui: /\bMiuiBrowser\//i,
  instagram: /\bInstagram/i,
  chrome: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/,
  safari: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/,
  ie: /IEMobile|MSIEMobile/,
  firefox: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
};

class InApp {

  ua = '';

  constructor(useragent) {
    this.ua = useragent;
  }

  browser() {
    for (let key in BROWSER) {
      let value = BROWSER[key];
      let predicateReturnValue = value.test(this.ua);
      if (predicateReturnValue) {                   // just take the value
        return key;                               // return key
      }
    }
    return 'other';
  }

  isMobile() {
    return /(iPad|iPhone|Android|Mobile)/i.test(this.ua) || false;
  }

  isDesktop() {
    return !this.isMobile;
  }

  isInApp() {
    let navigator = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    const ios_ipad_webview = ios && !safari;

    return ios ? ( (!standalone && !safari) || ios_ipad_webview ) : userAgent.includes('wv');
  }
}

export default InApp;
