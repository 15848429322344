import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Divider, Form, Input, Typography, Modal, Row, Col} from 'antd';
import GoogleLogin from 'react-google-login';

import useApi from 'core/hooks/useAPI';
import {login, ssoLogin} from 'core/apiRequests';
import AuthService from 'core/services/AuthService';
import {AppContext} from 'core/state/AppProvider';
import {EntryContext} from 'core/state/EntryProvider';
import {images} from 'core/constants';

import {SHOW_FORGOT_PASS, SHOW_SIGN_UP} from 'views/Entry';

import Spacer from '../Spacer';

const {Text, Link} = Typography;

export default function Login() {

  const [trigger, loading] = useApi();
  const [triggerGoogleLogin, googleLoginLoading] = useApi();

  const [state, setState] = useContext(EntryContext);
  const {t} = useTranslation();
  const [, dispatch] = useContext(AppContext);

  const onFinish = (values) => {
    trigger(login(values.email, values.password))
      .then(res => {
        AuthService.saveUserDetails(res);
        dispatch({type: 'LOGIN'});
      })
      .catch(err => {
        Modal.error({
          title: t('entry.error.loginFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  const gotoSignUp = () => setState({
    ...state,
    current: SHOW_SIGN_UP
  });

  const onForgotPass = () => setState({
    ...state,
    current: SHOW_FORGOT_PASS
  })

  const onGoogleSuccess = (response) => {
    triggerGoogleLogin(ssoLogin({
      "accessToken": response.tokenId,
      "provider": "GOOGLE",
      "userId": response.googleId,
      "appId": ""
    }))
      .then(res => {
        AuthService.saveUserDetails(res);
        dispatch({type: 'LOGIN'});
      })
      .catch(err => {
        Modal.error({
          title: t('entry.error.loginFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      })
  }

  const onGoogleFailure = (response) => {
    console.log(response);
  }

  return (
    <div className="login">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark="optional"
      >

        <Form.Item
          label="Email"
          name="email"
          rules={requiredRule(t('entry.validationMsg.noEmail'))}
        >
          <Input size="large"/>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={requiredRule(t('entry.validationMsg.noPass'))}
        >
          <Input.Password size="large"/>
        </Form.Item>

        <Text className="tos">
          {'By singing in, you agree to the '}
          <Link href="https://www.astrog.in/legal-and-privacy" target="_blank">
            {'terms and services'}
          </Link>
          {' of AstroG.'}
        </Text>

        <Spacer height={8}/>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            {t('submit')}
          </Button>
        </Form.Item>

        <Button type="link" onClick={onForgotPass}>
          {t('entry.forgotPass.title')}
        </Button>

        <Divider plain>
          {t('entry.orSignInWith')}
        </Divider>

        <Row justify="center">
          <Col className="social-login">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_ID}
              buttonText="Google"
              scope='https://www.googleapis.com/auth/user.birthday.read'
              onSuccess={onGoogleSuccess}
              onFailure={onGoogleFailure}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  disabled={googleLoginLoading}
                >
                  <img src={images.icons.google} alt={''}/>
                  <span>Google</span>
                </button>
              )}
            />
          </Col>
        </Row>

      </Form>

      <Button type="link" onClick={gotoSignUp}>
        {t('entry.singUp')}
      </Button>

    </div>
  );
}

const requiredRule = (msg) => [{required: true, message: msg}];