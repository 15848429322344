import {useState} from "react";
import {useIsMounted} from "./useIsMounted";
import ApiService from "core/services/ApiService";

export default function useApi() {

  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  function trigger(request) {
    setLoading(true);

    return ApiService
      .exchange(request)
      .then(handleSuccess)
      .catch(handleFail);
  }

  function handleSuccess(res) {
    const {status, data} = res.data;

    if (!status && !data) {
      if (isMounted) {
        setLoading(false);
      }
      return res.data;
    }

    if (status === 'success') {
      if (isMounted) {
        setLoading(false);
      }
      return data;
    }
  }

  function handleFail(reason) {
    if (isMounted) {
      setLoading(false);
    }

    const {data, status, error} = reason.response?.data;

    return Promise.reject({
      status,
      code: reason.response?.status,
      data,
      error
    });
  }

  return [trigger, loading];
}