import React from 'react';
import {Row, Col} from "antd";
import {EnvironmentOutlined} from "@ant-design/icons";

import useIsMobile from "core/hooks/useIsMobile";

import SearchLocation from './SearchLocation';
import NameSearch from "./NameSearch";

export default function LocationAndNameSearch() {

  const {isMobile} = useIsMobile();

  return (
    <Row gutter={[0, {xs: 20}]} className={!isMobile && "search-main"}>
      <Col xs={24} sm={9} className={isMobile && "search-main"}>
        <Row>
          <Col className="location-icon center">
            <EnvironmentOutlined style={{color: 'pink', fontSize: '20px'}}/>
          </Col>
          <Col flex={1}>
            <SearchLocation/>
          </Col>
        </Row>
      </Col>
      {
        !isMobile && (
          <Col span={1} className="center">
            <div className="divider"/>
          </Col>
        )
      }
      <Col xs={24} sm={14} className={isMobile && "mt-2 search-main"}>
        <NameSearch/>
      </Col>
    </Row>
  );

}