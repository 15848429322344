import {Select} from 'antd';
import countries from 'assets/data/countries.json';

/** @namespace item.dialCode */
/** @namespace item.isoCode */
/** @namespace item.flag */

const styles = {

  select: {
    width: 90,
  },
  dropdown: {
    minWidth: 300,
  },
  option: {
    display: 'flex',
    alignItems: 'center'
  },
  flag: {
    width: '14px',
    height: '14px',
    marginRight: '14px'
  },
};

const SelectPhoneCode = (
  <Select
    showSearch
    style={styles.select}
    dropdownStyle={styles.dropdown}
    size="large"
    optionLabelProp="label"
    filterOption={(input, option) => {
      const search = input.toLowerCase();
      return option.item.name.toLowerCase().includes(search)
        || option.item.dialCode.toLowerCase().includes(search)
        || option.item.isoCode.toLowerCase().includes(search);
    }}
  >
    {
      countries.map(item => (
        <Select.Option
          value={item.dialCode}
          key={item.isoCode}
          label={item.dialCode}
          item={item}
        >
          <div style={styles.option}>
            <img
              src={item.flag}
              alt=""
              style={styles.flag}
            />
            <span>
                {` ${item.dialCode} ${item.name}`}
              </span>
          </div>
        </Select.Option>
      ))
    }
  </Select>
);

export default SelectPhoneCode;