import {useTranslation} from "react-i18next";
import Container from "../Container";
import {Col, Row, Typography} from "antd";
import Spacer from "../Spacer";
import React from "react";
import classNames from "classnames";

const {Title, Text} = Typography;

const DashboardItem = ({children, title, subtitle, className}) => {

  const {t} = useTranslation();

  return (
    <Container className={classNames("dashboard-item", className)}>
      <Row>
        <Col span={24}>
          <Title {...props.title}>
            {t(title)}
          </Title>
        </Col>
        <Col span={24}>
          <Text type="secondary" {...props.subtitle}>
            {t(subtitle)}
          </Text>
        </Col>
        <Col span={24}>
          <Spacer height={32}/>
        </Col>
        <Col span={24}>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

const props = {
  space: {
    direction: 'vertical',
    align: 'center',
    style: {
      width: '100%'
    },
    size: 0
  },
  title: {
    level: 2,
    className: 'text-center primary-color'
  },
  subtitle: {
    className: "text-center",
    style: {
      textAlign: 'center',
      margin: 'auto',
      display: 'inline-block'
    }
  }
}

export default DashboardItem;