import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";

import {astrologersFilterContext} from "core/state/AstrologersFilterProvider";
import useSearchInputState from "core/hooks/useSearchInputState";


const NameSearch = () => {

  const [filter, setFilter] = useContext(astrologersFilterContext);
  const {t} = useTranslation();
  const [searchValue, setSearchValue] = useSearchInputState(() => {
    setFilter({
      ...filter,
      search: searchValue
    });
  });

  return (
    <Input
      placeholder={t('searchAstrologer')}
      suffix={<SearchOutlined/>}
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
    />
  )

}

export default NameSearch;