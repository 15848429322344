import React from 'react';
import {Row, Col, Image, Space, Typography} from 'antd';
import 'assets/styles/components/AstroInto.less';
import useIsMobile from "core/hooks/useIsMobile";

import Container from "../Container";
import {SearchAstrologer} from "./SearchAstrologer";
import Spacer from "../Spacer";
import {images} from '../../core/constants';


const {Title, Text} = Typography;

const styles = {
  container: {
    background: `url("${images.bg.sky}") no-repeat, #0B0429`,
    backgroundSize: '100%'
  }
}

const AstroIntro = ({t}) => {

  const {isMobile} = useIsMobile()

  return (
    <div className="astro-intro" style={styles.container}>
      <Container>
        <Row align="middle">
          <Col xs={0} sm={12} className="img-container">
            <Image src={images.bg.wheel} preview={false} width={'100%'}/>
          </Col>
          <Col xs={24} sm={12}>
            <Space direction="vertical">
              <Title
                level={isMobile ? 3 : 1}
                className="white">
                {t('dashboard.intro.title')}
              </Title>
              <Text level={4} className="white">
                {t('dashboard.intro.desc')}
              </Text>
              <Spacer height={16}/>
              <SearchAstrologer/>
            </Space>
          </Col>
        </Row>
      </Container>
    </div>
  );

};

export default AstroIntro;