import React, {useContext, useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Layout} from 'antd';

import 'assets/styles/layout/GeneralUser.less';

import Dashboard from 'views/Dashboard';
import Astrologers from 'views/Astrologers';
import AstrologerProfile from 'views/AstrologerProfile';
import ChooseSlot from 'views/ChooseSlot';
import ConfirmSlot from 'views/ConfirmSlot';
import BookHoroscopeMatching from 'views/BookHoroscopeMatching';
import Settings from 'views/Settings';
import Consultations from 'views/Consultations';
import ConsultationDetail from 'views/ConsultationDetail';
import WriteReview from 'views/WriteReview';
import PaymentStatus from 'views/PaymentStatus';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import AppErrorBoundary from 'components/AppErrorBoundary';
import NotificationBanner from 'components/NotificationBanner';

import {AppContext} from 'core/state/AppProvider';
import AuthService from 'core/services/AuthService';
import useApi from 'core/hooks/useAPI';
import {me} from 'core/apiRequests';


const {Content} = Layout;

export default function GeneralUser() {

  const [, dispatch] = useContext(AppContext);
  const [trigger,] = useApi();

  useEffect(() => {
    if (AuthService.getUserDetails()) {
      dispatch({type: 'LOGIN'});
      trigger(me)
        .then(() => dispatch({type: 'LOGIN'}))
        .catch(() => dispatch({type: 'LOGOUT'}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NotificationBanner/>
      <Layout id="con">
        <Navbar id="head"/>
        <MemorizedAppContent/>
        <Footer/>
      </Layout>
    </>
  );
}

const AppContent = () => (
  <Content id="content">
    <AppErrorBoundary>
      <RouteList/>
    </AppErrorBoundary>
  </Content>
);

const MemorizedAppContent = React.memo(AppContent);

const RouteList = () => {
  return (
    <Routes>
      <Route path="/slot/:id/confirm" element={<ConfirmSlot/>}/>
      <Route path="/astrologers" element={<Astrologers/>}/>
      <Route path="/astrologer/:id/book-slot" element={<ChooseSlot/>}/>
      <Route path="/astrologer/:id/horoscope-matching" element={<BookHoroscopeMatching/>}/>
      <Route path="/astrologer/:id" element={<AstrologerProfile/>}/>
      <Route path="/settings/*" element={<Settings/>}/>
      <Route path="/consultations/*" element={<Consultations/>}/>
      <Route path="/consultation/:id" element={<ConsultationDetail/>}/>
      <Route path="/consult/:sessionId/review" element={<WriteReview/>}/>
      <Route path="/payment/callback" element={<PaymentStatus/>}/>
      <Route path="/" element={<Dashboard/>}/>
      <Route path="/*" element={<Navigate to="/"/>}/>
    </Routes>
  );
};

