import React, {useContext, useEffect, useRef, useState} from "react";
import {useLocation, Link} from "react-router-dom";
import {Alert, Typography} from 'antd';
import {parseISO, differenceInMilliseconds, subMinutes} from "date-fns";

import {AppContext} from 'core/state/AppProvider';
import useApi from 'core/hooks/useAPI';
import {upcomingConsultations} from 'core/apiRequests';

import Container from './Container';

const {Text} = Typography;


/** @namespace consultation.consultant.name */
/** @namespace consultation.id */

export default function NotificationBanner() {

  const location = useLocation();
  const [state, ] = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [exit, setExit] = useState(false);
  const [consultation, setConsultation] = useState();
  const [trigger,] = useApi();
  const timerRefs = useRef([]);

  const getConsultations = () => {
    trigger(upcomingConsultations(0, 10, ["VIDEO"]))
      .then(data => data.forEach(subscribeForStartEnd));
  };

  const subscribeForStartEnd = (consultation) => {
    const now = new Date();
    const diffToStart = differenceInMilliseconds(subMinutes(parseISO(consultation.startTime), 5), now);
    const diffToEnd = differenceInMilliseconds(parseISO(consultation.endTime), now);

    // meet start time in future
    if (diffToStart > 0) {
      startTimer(diffToStart, true, consultation);
      startTimer(diffToEnd, false, consultation);
    }
    // within meet startTime and endTime
    else if (diffToStart < 0 && diffToEnd > 0) {
      setConsultation(consultation);
      setShow(true);
      startTimer(diffToEnd, false, consultation);
      scrollToTop();
    }
  };

  const startTimer = (diff, show, consultation) => {
    const timer = setTimeout(() => {
      setConsultation(show ? consultation : null);
      setShow(show);
      if (show) {
        scrollToTop();
      }
    }, diff);
    timerRefs.current.push(timer);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  };

  useEffect(() => {
    timerRefs.current.forEach(timer => {
      clearTimeout(timer);
    });
    if (state.isLoggedIn) {
      getConsultations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoggedIn, state.reloadNB]);

  useEffect(() => {
    if (location.pathname?.startsWith('/conference/')) {
      setExit(true);
    }
  }, [location.pathname]);

  if (!show || exit) {
    return null;
  }

  return (
    <Alert
      style={{
        border: 'none',
        background: '#B39DDB',
      }}
      description={
        <Container className="center">
          <Text strong>
            {`You've an appointment with ${consultation.consultant.name}.`}
            <Link to={`/consultation/${consultation.id}`}>
              {" Click here "}
            </Link>
            {" to join"}.
          </Text>
        </Container>
      }
      type="error"
    />
  );
}