import AuthService from 'core/services/AuthService';

const AppReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_LOGIN':
      return {
        ...state,
        showEntry: action.showEntry
      };
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: true
      };
    case 'LOGOUT':
      AuthService.clearUserDetails();
      return {
        isLoggedIn: false
      };
    case 'REFRESH':
      return {
        ...state,
        refresh: state.refresh + 1
      };
    case 'RELOAD_NB': // NB: Notification banner
      return {
        ...state,
        reloadNB: state.reloadNB + 1
      };
    default:
      return state;
  }
};

export default AppReducer;