import React, {useState} from "react";
import {Col, Row, Typography} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {
  addDays,
  addWeeks,
  format, isBefore,
  isSameDay,
  isWithinInterval, subDays,
} from "date-fns";

import "assets/styles/components/booking/MobileCalendar.less";
import Spacer from '../Spacer';
import classNames from 'classnames';


const {Text} = Typography;

export default function MobileCalendar(
  {
    min = new Date(),
    max = addDays(new Date(), 90),
    daysPerPage = 7,
    onChange
  }
) {

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(addDays(start, daysPerPage));

  const onDayChange = (date) => {
    setSelectedDate(date);
    onChange?.(date);
  };

  const onPrev = () => {
    const newStart = subDays(start, daysPerPage);
    setStart(newStart);
    setEnd(subDays(end, daysPerPage));
    onDayChange(newStart);
  };

  const onNext = () => {
    const newStart = addDays(start, daysPerPage);
    setStart(newStart);
    setEnd(addDays(end, daysPerPage));
    onDayChange(newStart);
  };

  return (
    <div className="mob-calendar">
      <Header
        min={min}
        max={max}
        selectedDate={selectedDate}
        onPrev={onPrev}
        onNext={onNext}
      />
      <Spacer height={8}/>
      <Days
        selectedDate={selectedDate}
        start={start}
        end={end}
        onChange={onDayChange}
      />
    </div>
  );

}

function Header({min, max, selectedDate, onPrev, onNext}) {

  const interval = {
    start: subDays(min, 1),
    end: addDays(max, 1)
  };
  const prevDisabled = !isWithinInterval(addWeeks(selectedDate, -1), interval);
  const nextDisabled = !isWithinInterval(addWeeks(selectedDate, 1), interval);

  return (
    <Row className="cal-header">
      <Col>
        <Text>
          {format(selectedDate, "MMM yyyy")}
        </Text>
      </Col>
      <Col flex={1}/>
      <Col>
        <button onClick={onPrev} disabled={prevDisabled}>
          <LeftOutlined/>
        </button>
      </Col>
      <Col>
        <button onClick={onNext} disabled={nextDisabled}>
          <RightOutlined/>
        </button>
      </Col>
    </Row>
  );
}

function Days({selectedDate, start, end, onChange}) {

  const dateFormat = "EEEEEE";

  let days = [];
  let i = 0;

  while (isBefore(start, end)) {

    const selectedClass = isSameDay(start, selectedDate) ? 'selected' : null;
    let copy = start;

    days.push(
      <div className="day" key={++i}>
        <div className="weekday">
          {format(start, dateFormat)}
        </div>
        <div
          className={classNames("day-number", selectedClass)}
          onClick={() => onChange(copy)}
        >
          {format(start, 'd')}
        </div>
      </div>
    );

    start = addDays(start, 1);
  }

  return (
    <div className="days">
      {days}
    </div>
  );
}