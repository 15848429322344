import React from 'react';
import {List, Avatar, Row, Col, Space, Typography, Rate} from "antd";
import {UserOutlined} from '@ant-design/icons';
import ReactTimeAgo from 'react-time-ago';
import {useTranslation} from "react-i18next";

import {imgBaseUrl} from "core/constants";
import Spacer from "../Spacer";
import {parseISO} from 'date-fns';

const {Text, Paragraph} = Typography;

const starStyle = {
  fontSize: '14px'
};

export default function FeedbackReviews({reviews}) {

  const {t} = useTranslation();

  return (
    <List
      itemLayout="horizontal"
      dataSource={reviews}
      renderItem={(review) => (
        <Row gutter={16}>
          <Col>
            <Avatar
              src={review.avatarUrl ? (imgBaseUrl + review.avatarUrl) : undefined}
              icon={<UserOutlined/>}
              size={44}
            />
          </Col>
          <Col flex="1">
            <Space className="w100" direction="vertical" size={0}>
              <Text style={{fontWeight: 500}}>
                {review.name}
              </Text>
              <StartAndTime review={review}/>
              <Spacer height={8}/>
              <Feedback feedback={review.feedback} t={t}/>
              <Spacer height={12}/>
            </Space>
          </Col>
        </Row>
      )}
    />
  );
}

function StartAndTime({review}) {
  return (
    <Row align="middle" gutter={8}>
      <Col>
        <Rate
          defaultValue={review.rating}
          disabled
          style={starStyle}
        />
      </Col>
      <Col>
        <Text type="secondary" className="time-ago">
          {review.ratedOn && <ReactTimeAgo date={parseISO(review.ratedOn)}/>}
        </Text>
      </Col>
    </Row>
  );
}

function Feedback({feedback, t}) {
  if (!feedback || feedback.trim().length === 0) {
    return (
      <Paragraph italic>
        {t('noReview')}
      </Paragraph>
    );
  }
  return (
    <Paragraph>
      {feedback}
    </Paragraph>
  );
}