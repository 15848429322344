import {useEffect, useState} from "react";
import {isBefore, isWithinInterval, parseISO, subMinutes} from "date-fns";

export default function useCanJoin(data) {

  const [canJoin, setCanJoin] = useState(false);

  useEffect(() => {
    if (data && isBefore(new Date(), parseISO(data?.endTime))) {
      updateJoin();
      const interval = setInterval(updateJoin, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateJoin = () => {
    const start = parseISO(data?.startTime);
    const end = parseISO(data?.endTime);
    const joinEnableInterval = {
      start: subMinutes(start, 5),
      end: end
    };

    const status = isWithinInterval(new Date(), joinEnableInterval);
    setCanJoin(() => status);
  }

  return [canJoin];

}