import {isBefore, parseISO} from 'date-fns';

/** @namespace review.reviewer */
/** @namespace details.notes */
/** @namespace details.consultationType */
/** @namespace details.customer */
/** @namespace review.createdOn */
/** @namespace item.note */
/** @namespace details.additionalInformation */

export const isAlreadyReviewed = (details) => {
  const review = details.reviews
    ?.find(review => review.reviewer === (details.customer?.id ?? details.userId));
  return review !== null && review !== undefined;
};

export const notesCount = (details) => {
  const count = details.notes?.length ?? 0;
  if (count === 0) return '0 notes';
  else if (count === 1) return '1 note';
  else return `${count} notes`;
};

export const showJoin = (details) => {
  return details.consultationType === 'VIDEO'
    && details.endTime
    && isBefore(new Date(), parseISO(details.endTime));
};

export const getTitle = (details) => {
  if (details.consultationType === 'VIDEO') {
    return 'book.videoCons';
  }
  if (details.consultationType === 'MESSAGE') {
    return 'book.horoscopeMatching';
  }
  return 'book.videoCons';
};

export const getReviews = (details) => {
  return details?.reviews.map(review => {
    const [user,] = findUser(details, review.reviewer);
    return {
      avatarUrl: user.avatarUrl,
      feedback: review.feedback,
      name: user.name,
      ratedOn: review.createdOn,
      rating: review.rating,
    }
  })
}

export const findUser = (details, id) => {
  if (details.customer.id === id) {
    return [details.customer, true];
  }
  if (details.consultant.userId === id) {
    return [details.consultant, false];
  }
};