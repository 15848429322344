import React, {useContext} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Col, Image, Layout, Row, Space} from 'antd';
import classNames from 'classnames';

import useIsMobile from 'core/hooks/useIsMobile';
import {AppContext} from 'core/state/AppProvider';
import {cdnImgBasePath, images} from 'core/constants';

import Container from './Container';
import LoginButton from './LoginButton';
import LoggedUserIcon from './LoggedUserIcon';

function Navbar(props) {

  const {logoType, color, className} = useDarkHeader();
  const [state,] = useContext(AppContext);

  return (
    <Layout.Header
      {...props}
      className={classNames('navbar', className)}
    >
      <Container>
        <Row>
          <Col>
            <Logo logoType={logoType}/>
          </Col>
          <Col flex="auto"/>
          <Col>
            <Space size={20}>
              <FindButton color={color}/>
              {state.isLoggedIn ? <LoggedUserIcon/> : <LoginButton/>}
            </Space>
          </Col>
        </Row>
      </Container>
    </Layout.Header>
  );
}

export default React.memo(Navbar);

const useDarkHeader = () => {
  const {pathname} = useLocation();

  let logoType, color, className;

  switch (pathname) {
    case "/":
      logoType = 'light';
      color = 'white';
      break;
    case "/astrologers":
      logoType = 'dark';
      color = 'black';
      break;
    default:
      logoType = 'dark';
      color = 'black';
      className = 'border';
  }

  return {logoType, color, className};
};

function Logo({logoType}) {

  const navigate = useNavigate();

  const logo = cdnImgBasePath + `/logo-${logoType}.svg`;

  return (
    <Image
      preview={false}
      src={logo}
      className="app-logo"
      onClick={() => navigate('/')}
    />
  );
}

function FindButton({color}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {isMobile} = useIsMobile();

  const handleClick = () => {
    navigate('/astrologers');
  };

  return (
    <>
      {
        isMobile ? (
          <Link to="/astrologers" style={{width: '24px', height: '24px'}}>
            <Image src={images.icons.discover} preview={false} width={24} height={24}/>
          </Link>
        ) : (
          <Button
            type="link"
            style={{color}}
            onClick={handleClick}
          >
            {t('findAstrologers')}
          </Button>
        )
      }
    </>
  );
}