import React from 'react';
import {Select} from 'antd';
import {EnvironmentFilled} from '@ant-design/icons'
import useFetch from "core/hooks/useFetch";
import {allAstrologers} from "core/apiRequests";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const {Option} = Select;

export const SearchAstrologer = () => {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const [data, loading, , refreshData] = useFetch(
    allAstrologers({}, undefined, undefined)
  );

  const handleSearch = (newValue) => {
    refreshData(allAstrologers({
      search: newValue,
    }, undefined, undefined));
  };

  const handleChange = (value) => {
    navigate(`/astrologer/${value}`);
  };

  return (
    <Select
      showSearch
      loading={loading}
      placeholder={t('searchAstrologer')}
      style={{
        width: '100%',
      }}
      suffixIcon={<EnvironmentFilled/>}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {
        (data ?? [])
          .map((d) => <Option key={d.id}>{d.name}</Option>)
      }
    </Select>
  );
};