import React from 'react';
import {Typography} from 'antd';

/** @namespace astrologer.aboutMe */

export default function About({astrologer}) {
  return (
    <div className="mt-3">
      <Typography.Paragraph>
        {astrologer.aboutMe}
      </Typography.Paragraph>
    </div>
  )
}