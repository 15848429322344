import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Typography, Space, Form, Input, Rate, Avatar, Button, Modal} from 'antd';

import 'assets/styles/views/WriteReview.less';

import {consultationDetails, postFeedback} from 'core/apiRequests';
import useApi from 'core/hooks/useAPI';
import {imgBaseUrl} from 'core/constants';
import useFetch from 'core/hooks/useFetch';
import useIsMobile from 'core/hooks/useIsMobile';

import Container from 'components/Container';
import {MediumHeading1} from 'components/Texts';
import Spacer from 'components/Spacer';

const {Text, Paragraph} = Typography;

const WriteReview = () => {

  let {sessionId} = useParams();
  const navigate = useNavigate();
  const [trigger, saving] = useApi();
  const {t} = useTranslation();
  const {isMobile} = useIsMobile();

  const [consultation, loading, error] = useFetch(consultationDetails(sessionId), res => {
    if (res.reviews?.find(r => r.reviewer === res?.customer.id)) {
      // user has already posted a review, go to consultation details page in this case
      navigate(`/consultation/${sessionId}`);
    }
  });

  const onFinish = (values) => {
    trigger(postFeedback({...values, sessionId: sessionId}))
      .then(() => navigate(`/consultation/${sessionId}`))
      .catch(err => {
        Modal.error({
          title: t('review.postFail'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };


  if (loading || error) {
    return null;
  }

  return (
    <Container className="main-pv write-review">
      <Row gutter={44}>

        {!isMobile && (
          <Col className="col-pt" span={7}>
            <Paragraph>{t('review.hint1')}</Paragraph>
            <Paragraph>{t('review.hint2')}</Paragraph>
            <Paragraph>{t('review.hint3')}</Paragraph>
            <Paragraph>{t('review.hint4')}</Paragraph>
          </Col>
        )}

        {!isMobile && (
          <Col>
            <div className="separator"/>
          </Col>
        )}

        <Col flex="auto" className="col-pt">
          <Space direction="vertical" className="w100">
            <MediumHeading1 level={4}>
              {t('review.title')}
            </MediumHeading1>
            <Text>
              {t('review.subtitle', {
                name: consultation?.consultant.name
              })}
            </Text>
            <Spacer height={6}/>
            <ReviewForm
              consultation={consultation}
              onFinish={onFinish}
              saving={saving}
              t={t}
            />
          </Space>
        </Col>

      </Row>
    </Container>
  );
};

const ReviewForm = ({consultation, onFinish, saving, t}) => {
  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark="optional"
    >
      <Row gutter={12}>
        <Col>
          <Avatar src={imgBaseUrl + consultation?.consultant.avatarUrl} size={44}/>
        </Col>
        <Col>
          <Form.Item
            name="rating"
            rules={[{required: true, message: t('review.noRatingMsg')}]}
          >
            <Rate className="rate"/>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="feedback" label={t('review.feedbackLabel')}>
        <Input.TextArea size="large" rows={4}/>
      </Form.Item>

      <Spacer height={8}/>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="middle" loading={saving}>
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WriteReview;