import React from 'react';
import {Skeleton, Card, Row, Col} from 'antd';
import Spacer from "../Spacer";

export default function AstrologerGridLoader() {
  return (
    <Card>
      <Row justify="center">
        <Col>
          <Skeleton.Avatar active={true} size={132} shape="circle"/>
        </Col>
      </Row>

      <Spacer height={24}/>

      <Skeleton/>

    </Card>
  )
}
