import React from "react";
import {Space, Typography, Divider, Tag} from "antd";
import {getExperience} from "core/utils/AstroUtils";
import {useTranslation} from "react-i18next";
import Spacer from "../Spacer";
import ConsultingHours from "./ConsultingHours";

const {Text} = Typography;

export default function SecondaryInfo({astrologer}) {

  const {t} = useTranslation();

  return (
    <Space className="w100" direction="vertical" size={0}>
      <InfoDivider title={t('expertIn').toUpperCase()}/>
      <Categories categories={astrologer.category}/>
      <Spacer height={16}/>
      <InfoDivider title={t('experience').toUpperCase()}/>
      <Text>
        {getExperience(astrologer.experience)}
      </Text>
      <Spacer height={16}/>
      {
        astrologer.schedule && (
          <>
            <InfoDivider title={t('consultHour').toUpperCase()}/>
            <ConsultingHours schedule={astrologer.schedule}/>
          </>
        )
      }
    </Space>
  );
}

const Categories = ({categories}) => (
  <div>
    {
      categories?.map((item, key) => (
        <Tag color="green" key={key}>
          {item}
        </Tag>
      ))
    }
  </div>
)

const InfoDivider = ({title}) => (
  <Divider orientation="left" plain>
    <Text type="secondary" className="divider-text">
      {title}
    </Text>
  </Divider>
)