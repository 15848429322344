import React, {useMemo} from 'react';
import {Row, Col, Button, Space, Alert, Descriptions} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {format, parseISO} from 'date-fns';

import 'assets/styles/views/ConsultatoinDetail.less';

import useFetch from 'core/hooks/useFetch';
import useIsMobile from 'core/hooks/useIsMobile';
import useCanJoin from 'core/hooks/consultation/useCanJoin';
import {consultationDetails} from 'core/apiRequests';
import {useJoinMeet} from 'core/hooks/consultation/useJoinMeet';
import {findUser, getReviews, getTitle, isAlreadyReviewed, showJoin} from 'core/utils/ConsultationUtils';
import {bookingType, bookType} from 'core/constants';

import Container from 'components/Container';
import Header from 'components/booking/Header';
import MeetInfo from 'components/booking/MeetInfo';
import FeedbackReviews from 'components/astrologer-profile/FeedbackReviews';
import Spacer from 'components/Spacer';
import {MediumHeading2, MediumHeading3} from 'components/Texts';
import ConsultationDetailLoading from 'components/consultations/ConsultationDetailLoading';
import UnknownError from 'components/UnknownError';


const ConsultationDetail = () => {

  const {id} = useParams();
  const [details, loading, error, refresh] = useFetch(consultationDetails(id));
  const {isMobile} = useIsMobile();

  if (loading) {
    return <ConsultationDetailLoading/>;
  }

  if (error) {
    return (
      <UnknownError
        onRetryClick={() => refresh(consultationDetails(id))}
      />
    );
  }

  return (
    <Container className="main-pv consult-detail">
      <Header
        name={details.consultant.name}
        avatarUrl={details.consultant.avatarUrl}
        title={getTitle(details)}
      />
      <Spacer height={20}/>
      <Row gutter={{xs: 0, sm: 44}}>
        <Col xs={24} sm={11}>
          <MeetInfo
            data={details}
            bookType={details.type !== "PORUTHAM" ? bookingType.videoConsultation : bookingType.horoscopeMatching}
          />
        </Col>
        {isMobile && (
          <Col span={24}>
            <Spacer height={44}/>
          </Col>
        )}
        <Col xs={24} sm={details.type === "PORUTHAM" ? 13 : 11}>
          <Content details={details}/>
        </Col>
      </Row>
      <Spacer height={12}/>
    </Container>
  );
};

const Content = ({details}) => {

  const [canJoin] = useCanJoin(details);
  const [joining, joinMeet] = useJoinMeet(details.id);
  const showJoinButton = showJoin(details);
  const showReviewButton = !showJoinButton && !isAlreadyReviewed(details);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleReview = () => navigate(`/consult/${details.id}/review`);
  const reviews = useMemo(() => getReviews(details),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [details]
  );

  return (
    <Space direction="vertical" className="w100" size={24}>
      {(details.status === 'CLOSED' || details.status === 'CONSULTED') &&
        <OldConsultationWarning consultantId={details.consultant.id} t={t}/>
      }

      {details.type === bookType.PORUTHAM && (
        <>
          <HoroscopeMatchingDetails info={details.additionalInformation} gender={'M'}/>
          <HoroscopeMatchingDetails info={details.additionalInformation} gender={'F'}/>
        </>
      )}

      {showJoinButton && (
        <>
          {!canJoin && (
            <Alert
              description={t('consultation.joinWaitWarning')}
              type="info"
            />
          )}
          <Button className="action-btn" size="large" disabled={!canJoin || joining} onClick={joinMeet}>
            {t('consultation.join')}
          </Button>
        </>
      )}

      {showReviewButton && (
        <>
          <Alert
            description={t('consultation.notReviewedWarning')}
            type="error"
          />
          <Button className="action-btn" size="large" onClick={handleReview}>
            {t('consultation.rateNReview')}
          </Button>
        </>
      )}

      {(showJoinButton || showReviewButton) && <Spacer height={1}/>}

      {reviews && reviews.length > 0 && (
        <Reviews reviews={reviews} t={t}/>
      )}

      {(details.notes?.length ?? 0) > 0 && (
        <Notes details={details} t={t}/>
      )}
    </Space>
  );
};

const HoroscopeMatchingDetails = ({gender, info}) => {

  const data = info.find(item => item.gender === gender);
  if (!data) {
    return null;
  }

  return (
    <>
      <MediumHeading2>
        {gender === 'M' ? 'Boy' : 'Girl'}
      </MediumHeading2>
      <Spacer height={8}/>
      <Descriptions bordered layout="vertical">
        <Descriptions.Item label="Date of birth">
          {format(parseISO(data.dob), 'MM/dd/yyyy hh:mm a')}
        </Descriptions.Item>
        <Descriptions.Item label="Place of birth">
          {data.location}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

const Notes = ({details, t}) => {
  return (
    <>
      <MediumHeading2>
        {t('consultation.comments')}
      </MediumHeading2>
      {details.notes.map((item, key) =>
        <Note
          details={details}
          item={item}
          key={key}
        />
      )}
    </>
  );
};

const Reviews = ({reviews, t}) => {
  return (
    <>
      <MediumHeading2>
        {t('consultation.reviews')}
      </MediumHeading2>
      <Spacer height={8}/>
      <FeedbackReviews reviews={reviews}/>
    </>
  );
};

const Note = ({details, item}) => {
  const [user, isMe] = findUser(details, item.author);
  return (
    <Space className="note w100" direction="vertical">
      <MediumHeading3>
        {isMe ? 'You' : user.name}:
      </MediumHeading3>
      <span>{item.note}</span>
    </Space>
  );
};

const OldConsultationWarning = ({consultantId, t}) => {

  const navigate = useNavigate();

  const onYes = () => navigate(`/astrologer/${consultantId}/book-slot`);

  return (
    <Alert
      description={t('consultation.oldWarning')}
      type="warning"
      action={
        <Space direction="vertical" align="center">
          <Button size="small" ghost danger onClick={onYes}>
            {t('yes')}
          </Button>
        </Space>
      }
    />
  );
};

export default ConsultationDetail;