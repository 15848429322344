import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {Modal, message} from 'antd';
import {formatISO} from "date-fns";

import useApi from 'core/hooks/useAPI';
import {abortBooking, bookHoroscopeMatching, bookSlot as bookSlotApi, me} from 'core/apiRequests';
import {bookConsultationType, bookType} from 'core/constants';

/** @namespace data.paymentLink */


export default function useBookConsultation(type, consultationType) {

  const [booking, setBooking] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const navigate = useNavigate();
  const [trigger,] = useApi();
  const {t} = useTranslation();

  useEffect(() => {
    return () => {
      if (booking && orderId) {
        trigger(abortBooking(orderId));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking, orderId]);

  function bookSlot({slotId, consultantId, note, dob, location, details}) {
    setBooking(true);

    // Get the profile details of the user of check if he/she has updated their mobile number.
    trigger(me)
      .then(user => {
        if (!user.mobile) {

          // User has not updated their mobile number. Showing an error alert.
          Modal.error({
            title: t('book.noMobile'),
            content: t('book.noMobileDesc'),

            // Redirecting the user to the settings edit screen on clicking on
            onOk: () => navigate('/settings/edit')
          });
          setBooking(false);
        } else {
          processToPayment({
            slotId, consultantId, note, dob, location, details
          });
        }
      });
  }

  function processToPayment(
    {
      slotId,
      consultantId,
      note,
      dob,
      location,
      details,
    }
  ) {
    const payload = {
      consultantId,
      slotId,
      note,
      location,
      type,
      consultationType,
      details,
      ...(dob && {dob: formatISO(dob)})
    };

    let request;
    if (type === bookType.BOOK && consultationType === bookConsultationType.VIDEO) {
      request = bookSlotApi(payload);
    } else if (type === bookType.PORUTHAM && consultationType === bookConsultationType.MESSAGE) {
      request = bookHoroscopeMatching(payload);
    }
    if (request) {
      trigger(request)
        .then(onSuccess)
        .catch(onFail);
    } else {
      setBooking(false);
      message.error(`Invalid booking types: ${type} ${consultationType}`)
        .then(() => {
        });
    }
  }

  const onFail = err => {
    setBooking(false);
    Modal.error({
      title: t('book.failed'),
      content: err?.data?.message ?? t('unknownError'),
    });
  };

  const onSuccess = (data) => {
    setOrderId(data.orderId);
    window.location.href = data.paymentLink;
  };

  return [booking, bookSlot];
}
