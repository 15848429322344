import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Button, Col, Form, Input, Row, Tabs} from 'antd';

import useFetch from 'core/hooks/useFetch';
import {anAstrologer} from 'core/apiRequests';
import useIsMobile from 'core/hooks/useIsMobile';

import {bookConsultationType, bookingType, bookType} from 'core/constants';
import Container from 'components/Container';
import Header from 'components/booking/Header';
import Spacer from 'components/Spacer';
import MeetInfo from 'components/booking/MeetInfo';
import {DatePicker} from 'components/Pickers';
import ConsultationDetailLoading from 'components/consultations/ConsultationDetailLoading';
import UnknownError from 'components/UnknownError';
import {formatISO} from 'date-fns';
import useBookConsultation from '../core/hooks/consultation/useBookConsultation';

/** @namespace profile.rates */

export default function BookHoroscopeMatching() {

  const {id} = useParams();
  const [profile, profileLoading, profileError, refreshProfile] = useFetch(anAstrologer(id));
  const {t} = useTranslation();
  const {isMobile} = useIsMobile();
  const [booking, bookSlot] = useBookConsultation(bookType.PORUTHAM, bookConsultationType.MESSAGE);

  if (profileLoading) {
    return <ConsultationDetailLoading/>;
  }

  if (profileError) {
    return (
      <UnknownError
        onRetryClick={() => refreshProfile()}
      />
    );
  }

  const meetData = {
    consultant: {
      avatarUrl: profile.avatarUrl,
      name: profile.name,
      category: profile.category,
    },
    rate: profile.rates?.find(i => i.type === 'PORUTHAM')?.rate
  };

  const onFinish = (values) => {
    let payload = {
      consultantId: id,
      details: [
        {
          gender: "M",
          dob: formatISO(values.boyDob),
          location: values.boyPlace
        },
        {
          gender: "F",
          dob: formatISO(values.girlDob),
          location: values.girlPlace
        }
      ],
      note: values.note
    }
    bookSlot(payload);
  };

  return (
    <Container className="main-pv">
      <Header
        avatarUrl={profile.avatarUrl}
        name={profile.name}
        title={'book.checkHS'}
      />
      <Spacer height={20}/>
      <Row gutter={{xs: 0, sm: 64}}>
        <Col xs={24} sm={11}>
          <MeetInfo
            bookType={bookingType.horoscopeMatching}
            data={meetData}
          />
        </Col>
        {isMobile && (
          <Col span={24}>
            <Spacer height={24}/>
          </Col>
        )}
        <Col xs={24} sm={13}>
          <Form
            onFinish={onFinish}
            name="basic"
            layout="vertical"
            autoComplete="off"
            requiredMark={null}
          >
            <Row gutter={{xs: 0, sm: 32}}>
              <Party
                title="Boy"
                dobFormItemName="boyDob"
                placeFormItemName="boyPlace"
                t={t}
              />
              <Party
                title="Girl"
                dobFormItemName="girlDob"
                placeFormItemName="girlPlace"
                t={t}
              />
              <Col span={24}>
                <Form.Item label={t('book.leaveNote')} name="note">
                  <Input.TextArea rows={4}/>
                </Form.Item>
                <Spacer height={20}/>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" loading={booking}>
                    {t('submit')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

const Party = ({title, dobFormItemName, placeFormItemName, t}) => (
  <Col xs={24} sm={12}>
    <Tabs items={[{label: title, key: title}]}/>
    <Spacer height={16}/>

    <Form.Item
      label={t('book.dobLabel')}
      name={dobFormItemName}
      rules={fieldRule}
    >
      <DatePicker showTime size="large" style={{
        width: "100%",
      }}/>
    </Form.Item>

    <Form.Item
      label={t('book.placeLabel')}
      name={placeFormItemName}
      rules={fieldRule}
    >
      <Input size="large"/>
    </Form.Item>
  </Col>
);

const fieldRule = [{required: true, message: 'Field is required'}];