
import React, {useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Space, Dropdown, Row, Col} from 'antd';
import {CalendarFilled, CaretDownOutlined} from "@ant-design/icons";
import {AppContext} from '../../core/state/AppProvider';

// const TYPE_ALLOW_CALL_NOW = "ALLOW_CALL_NOW";
const TYPE_ALLOW_PORUTHAM = "ALLOW_PORUTHAM";

function getConf(configuration, type) {
  return configuration?.find(t => t.type === type);
}

const ConsultButtons = ({astrologer}) => {

  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const horoscopeMatch = useMemo(() =>
      getConf(astrologer?.configurations, TYPE_ALLOW_PORUTHAM)?.enabled,
    [astrologer]);

  const items = [
    {
      label: t('book.horoscopeMatching'),
      key: '1',
    },
  ];

  const onBook = () => {
    if (state.isLoggedIn) {
      navigate(`/astrologer/${astrologer.id}/book-slot`);
    } else {
      dispatch({type: 'SHOW_LOGIN', showEntry: true});
    }
  };

  const handleMenuClick = () => {
    if (state.isLoggedIn) {
      navigate(`/astrologer/${astrologer.id}/horoscope-matching`);
    } else {
      dispatch({type: 'SHOW_LOGIN', showEntry: true});
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col>
        <Button type="primary" icon={<CalendarFilled/>} size="large" onClick={onBook}>
          {t('book.bookAppointment')}
        </Button>
      </Col>
      {horoscopeMatch && (
        <Col>
          <Dropdown
            menu={{
              items,
              onClick: handleMenuClick,
            }}
            className="more-options-btn"
          >
            <Button>
              <Space>
                {t('moreOptions')}
                <CaretDownOutlined/>
              </Space>
            </Button>
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};

export default ConsultButtons;