import React from 'react';
import {Col, Divider, Row, Skeleton, Space} from 'antd';

import useIsMobile from 'core/hooks/useIsMobile';

import Container from '../Container';
import Spacer from '../Spacer';

export default function ConsultationDetailLoading() {

  const {isMobile} = useIsMobile();

  return (
    <Container className="main-pv consult-detail">

      <Row justify="space-between">
        {!isMobile && (
          <Col xs={24} sm={12}>
            <Space size={16}>
              <Skeleton.Avatar size={40} active/>
              <Skeleton.Input size="small" active/>
            </Space>
          </Col>
        )}
        <Col>
          <Skeleton.Input size="large" active/>
        </Col>
      </Row>

      <Divider style={{marginTop: '12px'}}/>
      <Spacer height={20}/>

      <Row gutter={{xs: 0, sm: 44}}>
        <Col xs={24} sm={11}>
          <Skeleton active/>
          <Skeleton active/>
        </Col>
        {isMobile && (
          <Col span={24}>
            <Spacer height={44}/>
          </Col>
        )}
        <Col xs={24} sm={11}>
          <Skeleton active/>
        </Col>
      </Row>

    </Container>
  );
}