import React from 'react';
import {useTranslation} from "react-i18next";
import {Avatar, Button, Carousel, Space, Typography} from 'antd';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons'
import useFetch from "core/hooks/useFetch";
import {featuredAstrologer} from "core/apiRequests";
import {imgBaseUrl} from "core/constants";
import Spacer from "../Spacer";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";

const {Title, Text} = Typography;

/** @namespace astrologer.avatarUrl **/

const iconStyle = {fontSize: '28px'};

const PrevArrow = ({className, style, onClick, currentSlide}) => {

  return (
    <Button
      className={classNames(className, "primary-color")}
      style={{...style,}}
      disabled={currentSlide === 0}
      icon={<LeftCircleOutlined style={iconStyle}/>}
      onClick={onClick}
    >
    </Button>
  )
}

const NextArrow = ({className, style, onClick, currentSlide, slideCount}) => {

  return (
    <Button
      className={classNames(className, "primary-color")}
      style={{...style,}}
      disabled={currentSlide === slideCount - 1}
      icon={<RightCircleOutlined style={iconStyle}/>}
      onClick={onClick}
    />
  )
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <PrevArrow/>,
  nextArrow: <NextArrow/>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

export default function FeaturedAstrologers() {

  const [astrologers, loading, error] = useFetch(featuredAstrologer());
  const {t} = useTranslation();

  if (loading || error) {
    return <></>;
  }

  return (
    <Carousel
      {...settings}
      slidesToShow={astrologers.length > 2 ? 3 : astrologers.length}
    >
      {
        astrologers.map((astrologer, index) =>
          <FeaturedAstrologer
            astrologer={astrologer}
            t={t}
            key={index}
          />
        )
      }
    </Carousel>
  );
}

function FeaturedAstrologer({astrologer, t}) {

  const navigate = useNavigate();

  const handleClick = () => navigate(`/astrologer/${astrologer.id}`)

  return (
    <Space
      direction="vertical"
      align="center"
      className="w100 cursor-pointer"
      onClick={handleClick}
    >
      <Avatar src={imgBaseUrl + astrologer.avatarUrl} size={184}/>
      <Spacer height={16}/>
      <Title level={3} className="primary-color" style={{width: '100%'}} ellipsis={true}>
        {astrologer.name}
      </Title>
      <Text strong={true}>
        Languages: {getLanguages(astrologer?.language)}
      </Text>
      <Text type="secondary" className="text-center">
        {astrologer.shortDesc ?? t('noDescription')}
      </Text>
    </Space>
  )
}

const getLanguages = (arr) => {
  const s = arr?.join([', ']);
  if (!s || s.length === 0) {
    return 'Not specified'
  }
  return s;
};