import React from 'react';
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Typography} from "antd";

import "assets/styles/views/Dashboard.less"
import AstroIntro from "components/dashboard/AstroIntro";
import DashboardItem from "components/dashboard/DashboardItem";
import DailyHoroscopes from "components/dashboard/DailyHoroscopes";
import FeaturedAstrologers from "components/dashboard/FeaturedAstrologers";
import useIsMobile from "../core/hooks/useIsMobile";
import {Link} from "react-router-dom";
import Spacer from "../components/Spacer";
import {images} from '../core/constants';

const {Text} = Typography;

export default function Dashboard() {

  const {t} = useTranslation();

  return (
    <div style={{width: "100%"}}>

      {/* adding a empty div with height equal to navbar height to fix page jumping up issue on scroll }*/}
      {/*<div style={headerPlaceHolderStyle}/>*/}

      <AstroIntro t={t}/>
      <Featured t={t}/>
      <AstroGWorking t={t}/>
      <DailyHoroscopes t={t}/>

    </div>
  )

}

const Divider = () => (
  <Col span={2}>
    <hr/>
  </Col>
);

const AstroGWorking = ({t}) => {

  return (
    <div className="dashboard-grey-item astro-g-working">
      <DashboardItem
        title="dashboard.working.howItWorks"
        subtitle="dashboard.working.howItWorksDesc"
      >
        <Row justify="center">
          <AstroGWorkingStep t={t} stepNo={1} img={images.working.step1}/>
          <Divider/>
          <AstroGWorkingStep t={t} stepNo={2} img={images.working.step2}/>
          <Divider/>
          <AstroGWorkingStep t={t} stepNo={3} img={images.working.step3}/>
          <Divider/>
          <AstroGWorkingStep t={t} stepNo={4} img={images.working.step4}/>
          <Divider/>
          <AstroGWorkingStep t={t} stepNo={5} img={images.working.step5}/>
        </Row>
      </DashboardItem>
    </div>
  )
}

const AstroGWorkingStep = ({t, stepNo, img}) => {

  return (
    <Col span={3}>
      <img src={img} alt="" className="w100"/>
      <Text className="step-text w100 text-center mt-1">
        {t(`dashboard.working.workingStep${stepNo}`)}
      </Text>
    </Col>
  );
}

const Featured = () => {

  const {isMobile} = useIsMobile();

  return (
    <DashboardItem
      className="featured-astrologers"
      title="dashboard.featuredAstrologers.title"
      subtitle="dashboard.featuredAstrologers.desc"
    >
      <FeaturedAstrologers/>
      <Spacer height="12px"/>
      {isMobile && (
        <Row justify="end">
          <Col>
            <Button type="link">
              <Link to="/astrologers">
                View all astrologers
              </Link>
            </Button>
          </Col>
        </Row>
      )}
    </DashboardItem>
  );
}