import React from "react";
import {useTranslation} from "react-i18next";
import {Row, Col, Typography, Space, Image} from "antd";
import {Link} from "react-router-dom";
import {PhoneFilled, MailFilled} from "@ant-design/icons";

import "assets/styles/components/Footer.less";

import Container from "./Container";
import {images} from '../core/constants';

const {Title, Text} = Typography;

const url_instagram = 'https://www.instagram.com/neramonline/';
const url_facebook = 'https://www.facebook.com/neramToday/';
const url_youtube = 'https://www.youtube.com/channel/UCFsbg8xBbicWl-ll8HaIxVg';

const Footer = (props) => {

  const {t} = useTranslation();

  return (
    <footer {...props} className="ag-footer">
      <div className="details">
        <Container>
          <Row gutter={[32, 32]}>
            <Col span={24} md={8}>
              <AboutUs t={t}/>
            </Col>
            <Col sm={24} md={8}>
              <ContactUs t={t}/>
            </Col>
            <Col sm={24} md={8}>
              <SocialMedia t={t}/>
            </Col>
          </Row>
        </Container>
      </div>
      <Copyright/>
    </footer>
  );
};

function AboutUs({t}) {
  return (
    <Space direction="vertical" className="about-us">
      <Title level={4} className="primary-color">
        {t('about')}
      </Title>
      <Link to={'/faq'} target="_blank" rel="noopener noreferrer">
        FAQs
      </Link>
      <Link to={'/legal-and-privacy'} target="_blank" rel="noopener noreferrer">
        Our Policy
      </Link>
    </Space>
  );
}

function ContactUs({t}) {
  return (
    <Space direction="vertical">
      <Title level={4} className="primary-color">
        {t('contactUs')}
      </Title>
      <Space size="middle">
        <PhoneFilled style={iconStyle}/>
        <Text>+91-813-801-5500</Text>
      </Space>
      <Space size="middle">
        <MailFilled style={iconStyle}/>
        <Text>support@astrog.in</Text>
      </Space>
    </Space>
  );
}

function SocialMedia({t}) {
  return (
    <Space direction="vertical">
      <Title level={4} className="primary-color">
        {t('followUs')}
      </Title>
      <Space>
        <SocialMediaItem id="fb" img={images.icons.socialMedia.fb} classes="facebook" title="Facebook"/>
        <SocialMediaItem id="ig" img={images.icons.socialMedia.ig} classes="instagram" title="Instagram"/>
        <SocialMediaItem id="yt" img={images.icons.socialMedia.yt} classes="youtube" title="Youtube"/>
      </Space>
    </Space>
  );
}

function SocialMediaItem({id, img, classes, title}) {
  return (
    <Space id={id} direction="vertical" align="center" onClick={onSMClick} className="sm-item">
      <Image src={img} preview={false} width={64} height={64}/>
      <span className={classes}>{title}</span>
    </Space>
  );
}

function Copyright() {
  return (
    <Row className="copyright">
      <Container>
        <Text className="white">
          © 2021 AstroG
        </Text>
      </Container>
    </Row>
  );
}

const onSMClick = (e) => {
  let url;
  switch (e.currentTarget.id) {
    case 'fb':
      url = url_facebook;
      break;
    case 'ig':
      url = url_instagram;
      break;
    case 'yt':
      url = url_youtube;
      break;
    default:
      url = null;
  }
  if (url) {
    openInNewTab(url);
  }
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const iconStyle = {
  color: '#00000080'
};

export default React.memo(Footer);