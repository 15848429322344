import React from 'react';
import {Space, Typography} from "antd";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export default function SlotLegend({direction, ...props}) {

  const {t} = useTranslation();

  return (
    <Space direction={direction} {...props}>
      <Legend color="#52c41a" text={t('book.available')}/>
      <Legend color="#ffcc73" text={t('book.booked')}/>
      <Legend color="#eeeeee" text={t('book.expired')}/>
    </Space>
  )
}

function Legend({color, text}) {
  return (
    <Space>
      <div style={{
        width: '12px',
        height: '12px',
        background: color,
        borderRadius: '50%'
      }}/>
      <Text type="secondary" style={{fontSize: '12px'}}>
        {text}
      </Text>
    </Space>
  )
}