import {Col, Rate, Row, Space, Typography} from "antd";
import {EnvironmentFilled} from "@ant-design/icons";
import Spacer from "../Spacer";
import ConsultButtons from "./ConsultButtons";
import React from "react";
import useIsMobile from "../../core/hooks/useIsMobile";

const {Title, Text} = Typography;

/** @namespace astrologer.profession */

export default function PrimaryInfo({astrologer}) {

  const rating = astrologer.rating ? +(Math.round(astrologer.rating + "e+1") + "e-1") : 0;
  const {isMobile} = useIsMobile();

  return (
    <Space direction="vertical" className="w100" size={0}>
      <Title level={3} className="primary-color">
        {astrologer.name}
      </Title>
      <Row justify="space-between">
        <Col span={isMobile ? 24 : undefined}>
          <Text>
            {astrologer.profession?.join(', ')}
          </Text>
        </Col>
        <Col span={isMobile ? 24 : undefined}>
          <Space align="center" className="location">
            <EnvironmentFilled style={{color: 'grey'}}/>
            <Text type="secondary">
              {astrologer.city}
            </Text>
          </Space>
        </Col>
      </Row>
      <Spacer height={20}/>
      <Text>Ratings</Text>
      <Space align="center" size={12}>
        <Text strong>
          {rating}
        </Text>
        <Rate
          allowHalf
          defaultValue={astrologer.rating}
          disabled={true}
        />
      </Space>
      <Spacer height={32}/>
      <ConsultButtons astrologer={astrologer}/>
    </Space>
  )
}