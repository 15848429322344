import React from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Col, Divider, Row, Space, Typography} from 'antd';

import {imgBaseUrl} from 'core/constants';
import useIsMobile from 'core/hooks/useIsMobile';
import {TitleLight} from '../Texts';

const {Text, Title} = Typography;

const styles = {
  avatar: {
    width: '40px',
    height: '40px'
  },
  divider: {
    marginTop: '12px'
  }
};

export default function Header({avatarUrl, name, title}) {

  const {t} = useTranslation();
  const {isMobile} = useIsMobile();

  return (
    <>
      <Row justify="space-between">
        {!isMobile && (
          <Col xs={24} sm={12}>
            <Space size={16}>
              <Avatar
                src={imgBaseUrl + avatarUrl}
                style={styles.avatar}
              />
              <Title level={5}>{name}</Title>
            </Space>
          </Col>
        )}
        <Col>
          <Space direction="vertical" size={0}>
            <TitleLight>
              {t(title)}
            </TitleLight>
            {isMobile && (
              <Text>
                {name}
              </Text>
            )}
          </Space>
        </Col>
      </Row>
      <Divider style={styles.divider}/>
    </>
  );
}