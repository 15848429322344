import React, {useContext} from 'react';
import {Avatar, Dropdown} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../core/state/AppProvider';
import {useNavigate} from 'react-router-dom';

const LoggedUserIcon = () => {

  const {t} = useTranslation();
  const [, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  const items = [
    {label: t('myConsultations'), key: '1'},
    {label: t('settings.title'), key: '2'},
    {type: 'divider'},
    {label: t('logout'), key: '3'},
  ];

  const onClick = ({key}) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case '1':
        navigate('/consultations');
        break;
      case '2':
        navigate('/settings');
        break;
      case '3':
        dispatch({type: 'LOGOUT'});
        navigate('/');
        break;
    }
  };

  return (
    <Dropdown menu={{items, onClick}} placement="bottomRight">
      <Avatar size={32} className="bg-primary">
        <UserOutlined/>
      </Avatar>
    </Dropdown>
  );
};

export default LoggedUserIcon;