import React from 'react';
import {Button, Result} from "antd";
import Container from './Container';
import {useTranslation} from 'react-i18next';

export default function UnknownError({onRetryClick}) {

  const {t} = useTranslation();

  return (
    <Container className="main-pv">
      <Result
        status="500"
        title={t('unknownErrorShort')}
        subTitle={t('unknownError')}
        extra={
          <Button type="primary" onClick={onRetryClick}>
            {t('reload')}
          </Button>
        }
      />
    </Container>
  );
}