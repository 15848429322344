import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {Select} from "antd";
import useFetch from "core/hooks/useFetch";
import {locations} from "core/apiRequests";
import {astrologersFilterContext} from "core/state/AstrologersFilterProvider";

/** @namespace item.city */

export default function SearchLocation() {

  const [filter, setFilter] = useContext(astrologersFilterContext);
  const {t} = useTranslation();
  const [data, loading, ,] = useFetch(locations);

  const handleChange = (value) => {
    setFilter({
      ...filter,
      location: value
    });
  };

  return (
    <Select
      loading={loading}
      showSearch
      placeholder={t('location')}
      className="w100"
      allowClear
      defaultActiveFirstOption={false}
      showArrow={true}
      onChange={handleChange}
      filterOption={(input, option) => {
        return option.children.toLowerCase().includes(input)
      }}
    >
      {
        (data ?? [])
          .map((item, key) => (
              <Select.Option key={key} value={item.city[0]}>
                {item.city[0]}
              </Select.Option>
            )
          )
      }
    </Select>
  )
}