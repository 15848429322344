import React, {useContext, useEffect} from 'react';
import {Col, Modal, Row, Typography} from 'antd';

import 'assets/styles/views/Entry.less';

import useIsMobile from 'core/hooks/useIsMobile';
import {EntryContext} from 'core/state/EntryProvider';

import Login from 'components/entry/Login';
import SignUp from "components/entry/SignUp";
import SignupVerify from 'components/entry/SignupVerify';
import ForgotPass from 'components/entry/ForgotPass';
import ForgotPassVerify from 'components/entry/ForgotPassVerify';
import {images} from '../core/constants';

const {Title} = Typography;

const SHOW_LOGIN = 1;
const SHOW_SIGN_UP = 2;
const SHOW_SIGN_UP_OTP = 3;
const SHOW_FORGOT_PASS = 4;
const SHOW_FORGOT_PASS_VERIFY = 5;

export default function Entry({open, onClose, showOTP}) {

  const {isMobile} = useIsMobile();
  const [state, setState] = useContext(EntryContext);

  useEffect(() => {
    if (showOTP) {
      const data = JSON.parse(localStorage.getItem("otpData"));
      setState({
        email: data.email,
        mobile: data.mobile,
        current: SHOW_SIGN_UP_OTP,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOTP]);

  return (
    <Modal
      width={isMobile ? '100%' : 768}
      open={open}
      onCancel={onClose}
      footer={null}
      closable={true}
    >
      <Row className="entry">
        <Col xs={0} sm={10} className="side-view">
          <Title className="white" level={2}>Sign in</Title>
          <Title className="white" level={5}>
            Get in touch with AstroG’s top professional astrologers. Tap their expertise and deep knowledge.
          </Title>
          <div className="flex-grow-1"/>
          <img src={images.bg.wheel} className="wheel" alt=""/>
        </Col>
        <Col xs={24} sm={14} className="content">
          {
            state.current === SHOW_LOGIN ? <Login/>
              : state.current === SHOW_SIGN_UP ? <SignUp/>
                : state.current === SHOW_SIGN_UP_OTP ? <SignupVerify/>
                  : state.current === SHOW_FORGOT_PASS ? <ForgotPass/>
                    : state.current === SHOW_FORGOT_PASS_VERIFY ? <ForgotPassVerify/>
                      : <></>
          }
        </Col>
      </Row>

    </Modal>
  );
}

export {SHOW_LOGIN, SHOW_SIGN_UP, SHOW_SIGN_UP_OTP, SHOW_FORGOT_PASS, SHOW_FORGOT_PASS_VERIFY};