import React from 'react';
import {useTranslation} from 'react-i18next';
import {Space, Typography} from 'antd';

import AstrologersFilterProvider from 'core/state/AstrologersFilterProvider';
import {images} from 'core/constants';

import Container from 'components/Container';
import LocationAndNameSearch from 'components/astrologers/LocationAndNameSearch';
import List from 'components/astrologers/List';

import Sorts from "components/astrologers/Sorts";
import Spacer from 'components/Spacer';

import 'assets/styles/views/Astrologers.less';


const {Title, Text} = Typography;

const coverStyle = {
  width: '100%',
  background: `url("${images.bg.temple}") no-repeat`,
  backgroundSize: 'cover',
}

export default function Astrologers() {

  return (
    <AstrologersFilterProvider>
      <Cover/>
      <Sorts/>
      <List/>
    </AstrologersFilterProvider>
  );
}

function Cover() {

  const {t} = useTranslation();

  return (
    <div style={coverStyle}>
      <Container className="astro-list-cover">
        <Space direction="vertical" size={0} className="w100">
          <Title level={2}>
            {t('astrologersList.h1')}
          </Title>
          <Text style={{fontWeight: 500}}>
            {t('astrologersList.h2')}
          </Text>
          <Spacer height={32}/>
          <LocationAndNameSearch/>
        </Space>
      </Container>
    </div>
  );
}