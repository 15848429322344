import React, {useContext} from 'react';
import {Button, Form, Input, Modal, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import {SHOW_FORGOT_PASS_VERIFY, SHOW_LOGIN} from 'views/Entry';

import useApi from 'core/hooks/useAPI';
import {EntryContext} from 'core/state/EntryProvider';
import {forgotPass} from 'core/apiRequests';

import Spacer from '../Spacer';

const {Title, Text} = Typography;

export default function ForgotPass() {

  const [state, setState] = useContext(EntryContext);
  const {t} = useTranslation();
  const [trigger, loading] = useApi();

  const onFinish = (values) => {
    trigger(forgotPass(values.email))
      .then(() => {
        setState({
            ...state,
            email: values.email,
            current: SHOW_FORGOT_PASS_VERIFY,
          }
        );
      })
      .catch(err => {
        Modal.error({
          content: err?.data?.message ?? t('unknownError'),
        });
      })
  };

  const handleLogin = () => {
    setState({
      ...state,
      current: SHOW_LOGIN
    })
  }

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark="optional"
    >

      <Title level={4}>
        {t('entry.forgotPass.title')}
      </Title>
      <Text>
        {t('entry.forgotPass.subtitle')}
      </Text>

      <Spacer height={24}/>

      <Form.Item
        label="Email"
        name="email"
        rules={[{required: true, message: t('entry.forgotPass.enterEmail')}]}
      >
        <Input size="large"/>
      </Form.Item>

      <Spacer height={16}/>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          {t('submit')}
        </Button>
      </Form.Item>

      <Spacer height={16}/>

      <Button type="link" size="small" onClick={handleLogin}>
        {t('entry.forgotPass.login')}
      </Button>

    </Form>
  );
}