import axios from "axios";
// import {setupCache} from 'axios-cache-adapter'

import AuthService from "./AuthService";
import {apiServer} from 'core/config'

// Create `axios-cache-adapter` instance
// const cache = setupCache({
//   maxAge: 15 * 60 * 1000
// })

const instance = axios.create({
  baseURL: apiServer.url,
  timeout: apiServer.timeout,
  // adapter: cache.adapter
});

instance.interceptors.request.use(
  config => {
    const token = AuthService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

const ApiService = {

  get(url, options = null) {
    return instance.get(url, getAxiosOptions(options))
      .then(res => res.data)
      .catch(reason => Promise.reject(reason));
  },

  post(url, data, options = null) {
    return instance.post(url, data, getAxiosOptions(options))
      .then(res => res.data)
      .catch(reason => Promise.reject(reason));
  },

  put(url, data, options = null) {
    return instance.put(url, data, getAxiosOptions(options))
      .then(res => res.data)
      .catch(reason => Promise.reject(reason));
  },

  patch(url, data, options = null) {
    return instance.patch(url, data, getAxiosOptions(options))
      .then(res => res.data)
      .catch(reason => Promise.reject(reason));
  },

  delete(url, payload, options) {
    return instance.delete(url, {
      ...getAxiosOptions(options),
      data: payload
    })
      .then(res => res.data)
      .catch(reason => Promise.reject(reason));
  },

  awaitAll() {
    return axios.all(Array.from(arguments))
      .then(axios.spread((...responses) => responses))
      .catch(reasons => Promise.reject(reasons));
  },

  exchange(request) {
    return instance(request)
      .then(res => res)
      .catch(reason => Promise.reject(reason));
  },

  getCancelToken() {
    return new AbortController();
  },

  getCancelOption(controller) {
    return {
      signal: controller.signal
    }
  },

  cancel(token) {
    if (token) {
      token.abort();
    }
  }

};

function getAxiosOptions(options) {
  const axiosOptions = {};
  if (options) {
    if (options.cancelToken) {
      axiosOptions.cancelToken = options.cancel.token;
    }
    if (options.cache) {
      axiosOptions.cache = options.cache;
    }
  }
  return axiosOptions;
}

export default ApiService;