import {isAndroid} from 'react-device-detect';
import {Row, Col, Alert, Typography} from 'antd';
import {AndroidFilled} from '@ant-design/icons';
import Container from './Container';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const MobileAppAlert = () => {

  const location = useLocation();
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (location.pathname?.startsWith('/conference/')) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  if (show && isAndroid) {
    return (
      <Alert
        message={
          <Container>
            <Row gutter={8} align="middle" justify="start">
              <Col>
                <AndroidFilled style={{color: '#a4c639', fontSize: 24}}/>
              </Col>
              <Col>
                <Typography.Link href="https://play.google.com/store/apps/details?id=com.neram.astrog">
                  Get AstroG for Android and browse faster.
                </Typography.Link>
              </Col>
            </Row>
          </Container>
        }
        type="warning"
      />
    );
  }
};

export default MobileAppAlert;