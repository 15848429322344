import {Card, Col, Row, Skeleton} from 'antd';
import React from 'react';

export default function ConsultationListItemLoader() {
  return (
    <Card className="list-item">
      <Row gutter={20}>
        <Col flex="none">
          <Skeleton.Avatar size={54} active/>
        </Col>
        <Col flex="auto">
          <Skeleton paragraph={{rows: 1}}/>
        </Col>
      </Row>
    </Card>
  );
}