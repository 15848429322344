
import React from 'react';
import {Avatar, Card, Col, Rate, Row, Space, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';

import {imgBaseUrl} from "core/constants";
import {getExperience} from "core/utils/AstroUtils";
import Spacer from "../Spacer";
import {useNavigate} from "react-router-dom";

const {Title, Text} = Typography;

/** @namespace data.shortDesc */
/** @namespace data.experience */

const styles = {
  card: {
    height: '100%'
  },
  star: {
    fontSize: '17px'
  }
}

export default function AstrologerGrid({data}) {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/astrologer/${data.id}`)
  }

  return (
    <Card
      style={styles.card}
      hoverable
      onClick={handleClick}
    >

      <Row justify="center">
        <Col>
          <Avatar
            src={imgBaseUrl + data.avatarUrl}
            size={132}
          >
            <UserOutlined/>
          </Avatar>
        </Col>
      </Row>

      <Spacer height={16}/>

      <Space direction="vertical" className="w100">

        <Title level={4} className="primary-color">
          {data.name}
        </Title>

        <span>
          <Text strong>
            {'Exp: '}
          </Text>
          <Text type="secondary" strong>
            {getExperience(data.experience)}
          </Text>
        </span>

        <Text ellipsis={{tooltip: data.shortDesc}}>
          {data.shortDesc}
        </Text>

        <Rate
          allowHalf
          defaultValue={data.rating}
          disabled
          style={styles.star}
        />
      </Space>

    </Card>
  )
}