import React from 'react';
import {Typography, Form, Input, Button, notification, Modal} from 'antd';
import {useTranslation} from 'react-i18next';

import useApi from 'core/hooks/useAPI';
import {changePassword} from 'core/apiRequests';
import {requiredRule} from 'core/utils/FormUtils';

import Spacer from '../Spacer';

const {Title} = Typography;

export default function ChangePassword() {

  const {t} = useTranslation();
  const [trigger, loading] = useApi();

  const onFinish = (values) => {
    trigger(changePassword(values.oldPassword, values.newPassword))
      .then(() => {
        notification.info({
          message: t('settings.changePassword.updateSuccess'),
          description: t('settings.changePassword.updateSuccessDesc'),
          placement: 'bottom',
        });
      })
      .catch(err => {
        Modal.error({
          title: t('settings.changePassword.updateFail'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  return (
    <div className="content">
      <Title level={3}>
        {t('settings.changePassword.title')}
      </Title>

      <Spacer height={12}/>

      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark="optional"
      >
        <Form.Item
          label={t('settings.changePassword.oldPass')}
          name="oldPassword"
          rules={[requiredRule(t('settings.changePassword.validation.oldPassRequired'))]}
        >
          <Input.Password size="large"/>
        </Form.Item>

        <Form.Item
          label={t('settings.changePassword.newPass')}
          name="newPassword"
          rules={[requiredRule(t('settings.changePassword.validation.newPassRequired'))]}
        >
          <Input.Password size="large"/>
        </Form.Item>

        <Form.Item
          label="Confirm password"
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            requiredRule(t('settings.changePassword.validation.confirmPassRequired')),
            passMatchRule,
          ]}
        >
          <Input.Password size="large" visibilityToggle={false}/>
        </Form.Item>

        <Spacer height={20}/>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('submit')}
          </Button>
        </Form.Item>

      </Form>

    </div>
  );
}

const passMatchRule = ({getFieldValue}) => ({
  validator(_, value) {
    if (!value || getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Passwords do not match.'));
  },
});