import React from 'react';
import {Col, Row, Space, Typography} from "antd";
import {
  format,
  parse,
} from "date-fns";

const {Text} = Typography;

/** @namespace item.day */

export default function ConsultingHours({schedule}) {
  return (
    <Space direction="vertical" className="w100">
      {
        schedule.map((item, key) => <Day key={key} item={item}/>)
      }
    </Space>
  )
}

function Day({item}) {
  const day = format(parse(item.day, 'ccc', new Date()), 'cccc');
  const startTime = format(parse(item.startTime, 'HH:mm:ssxxx', new Date()), "h:mm a");
  const endTime = format(parse(item.endTime, 'HH:mm:ssxxx', new Date()), "h:mm a");

  return (
    <Row>
      <Col span={10}>
        <Text type="secondary">
          {day}
        </Text>
      </Col>
      <Col flex="auto">
        <Text>
          {`${startTime}  -  ${endTime}`}
        </Text>
      </Col>
    </Row>
  );

}