import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {Avatar, Card, Col, Row, Space, Typography, Button} from 'antd';
import {PlusSquareFilled, VideoCameraFilled} from '@ant-design/icons';
import {format, isValid, parseISO} from 'date-fns';

import {bookType, imgBaseUrl} from 'core/constants';
import {isAlreadyReviewed, notesCount, showJoin} from 'core/utils/ConsultationUtils';
import useCanJoin from 'core/hooks/consultation/useCanJoin';
import {useJoinMeet} from 'core/hooks/consultation/useJoinMeet';
import {useTranslation} from 'react-i18next';

const {Text} = Typography;

const ConsultationListItem = ({consultation, pending = false}) => {

  const [canJoin] = useCanJoin(consultation);
  const [joining, joinMeet] = useJoinMeet(consultation.id);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleClick = (e) => {
    const id = e.target.id;
    if (id === "joinBtn" || id === "rateAndReview") {
      return;
    }
    navigate(`/consultation/${consultation.id}`);
  };

  return (
    <Card className="list-item" onClick={handleClick} hoverable>
      <Row gutter={12} wrap={false} justify="center">
        <Col flex="none" className="center">
          <Avatar src={imgBaseUrl + consultation.consultant.avatarUrl}/>
        </Col>
        <Col flex="auto">
          <Space direction="vertical" size={0}>
            <Text strong>
              {consultation.consultant.name}
            </Text>
            {consultation.startTime && (
              <Text>
                {formatted(consultation.startTime)}
              </Text>
            )}
            <Space>
              <MeetIcon type={consultation.type}/>
              <Text>
                {notesCount(consultation)}
              </Text>
            </Space>
          </Space>
        </Col>
        <Col flex="none" className="center">
          <Space direction="vertical" align={"end"} size={0}>
            {showJoin(consultation) && (
              <Button id="joinBtn" disabled={!canJoin || joining} onClick={joinMeet}>
                {t('consultation.join')}
              </Button>
            )}
            {!pending && (
              <Space align="center">
                <Text className="primary-color">₹</Text>
                <Text strong>{consultation.rate}</Text>
              </Space>
            )}
            {!pending && !isAlreadyReviewed(consultation) && (
              <Link id="rateAndReview" to={`/consult/${consultation.id}/review`}>
                {t('consultation.rateNReview')}
              </Link>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

const MeetIcon = ({type}) => type === bookType.PORUTHAM ?
  <PlusSquareFilled style={{color: '#ff69b4'}}/> :
  <VideoCameraFilled style={{color: '#0A796B'}}/>;

const formatted = (date) => {
  date = parseISO(date);
  if (date && isValid(date)) {
    return format(date, 'dd/MM/yyyy h:mm a');
  }
  return '';
};

export default ConsultationListItem;