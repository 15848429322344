const TitleLight = ({children, level = 2}) =>
  <TextItem
    size={
    level === 1 ? 32
      : level === 2 ? 29
        : level === 3 ? 26
          : level === 4 ? 23
            : level === 5 ? 20 : 17
  } weight={300}>
    {children}
  </TextItem>;

const MediumHeading1 = ({children, style}) =>
  <TextItem size={20} weight={500} style={style}>
    {children}
  </TextItem>;

const MediumHeading2 = ({children, style}) =>
  <TextItem size={17} weight={500} style={style}>
    {children}
  </TextItem>;

const MediumHeading3 = ({children}, style) =>
  <TextItem size={15} weight={500} style={style}>
    {children}
  </TextItem>;

const MediumHeading4 = ({children}, style) =>
  <TextItem size={13} weight={500} style={style}>
    {children}
  </TextItem>;

const TextItem = ({children, size, weight, style}) => {
  return (
    <span style={{
      ...style,
      fontSize: `${size}px`,
      fontWeight: weight,
    }}
    >
      {children}
    </span>
  );
};

export {TitleLight, MediumHeading1, MediumHeading2, MediumHeading3, MediumHeading4};
