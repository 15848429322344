import React from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Col, Menu, Row} from 'antd';

import 'assets/styles/views/Settings.less';

import Container from 'components/Container';
import EditProfile from 'components/settings/EditProfile';
import UpdateLocation from 'components/settings/UpdateLocation';
import ChangePassword from 'components/settings/ChangePassword';

import useIsMobile from 'core/hooks/useIsMobile';

const items = [
  {label: 'Edit profile', key: 'edit'},
  {label: 'Update location', key: 'update-location'},
  {label: 'Change password', key: 'change-password'},
];

const Settings = () => {

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {isMobile} = useIsMobile();

  const onClick = (e) => {
    navigate(`/settings/${e.key}`);
  };

  const menuProps = {
    ...!isMobile && {
      style: {width: '232px'},
      flex: "none",
    },
    ...isMobile && {
      span: 24
    }
  };

  const contentProps = {
    ...isMobile && {
      span: 24
    },
    ...!isMobile && {
      flex: "auto"
    }
  }

  return (
    <Container className="main-pv h100 settings">
      <Row wrap={isMobile}>
        <Col {...menuProps}>
          <Menu
            onClick={onClick}
            selectedKeys={[pathname.split('/')?.pop()]}
            mode={isMobile ? "horizontal" : "vertical"}
            items={items}
          />
        </Col>
        <Col {...contentProps}>
          <Routes>
            <Route path="edit" element={<EditProfile/>}/>
            <Route path="update-location" element={<UpdateLocation/>}/>
            <Route path="change-password" element={<ChangePassword/>}/>
            <Route path="/*" element={<Navigate to="/settings/edit"/>}/>
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};


export default Settings;