import React from 'react';
import {useParams} from "react-router-dom";
import {Col, Image, Row, Tabs} from "antd";
import {UserOutlined} from "@ant-design/icons";

import 'assets/styles/views/AstrologerProfile.less';

import useFetch from "core/hooks/useFetch";
import {anAstrologer} from "core/apiRequests";
import {imgBaseUrl} from "core/constants";

import Container from "components/Container";
import PrimaryInfo from "components/astrologer-profile/PrimaryInfo";
import Spacer from "components/Spacer";
import SecondaryInfo from "components/astrologer-profile/SecondaryInfo";
import {useTranslation} from "react-i18next";
import About from "components/astrologer-profile/About";
import Feedbacks from "components/astrologer-profile/Feedbacks";
import AstroProfileLoader from "components/astrologer-profile/AstroProfileLoader";
import UnknownError from "components/UnknownError";

/** @namespace astrologer.rating */

export default function AstrologerProfile() {

  const {id} = useParams();
  const [astrologer, loading, error, refresh] = useFetch(anAstrologer(id))

  if (loading) {
    return <AstroProfileLoader/>;
  }

  if (error) {
    return (
      <UnknownError
        onRetryClick={() => refresh(anAstrologer(id))}
      />
    )
  }

  return (
    <Container className="main-pt astro-profile">
      <Row>
        <Col span={6}>
          <Image
            className="avatar"
            icon={<UserOutlined/>}
            src={imgBaseUrl + astrologer.avatarUrl}
          />
        </Col>
        <Col span={18} className="primary-info">
          <PrimaryInfo astrologer={astrologer}/>
        </Col>
        <Col span={24}>
          <Spacer height={20}/>
        </Col>
        <Col xs={24} sm={6}>
          <SecondaryInfo astrologer={astrologer}/>
        </Col>
        <Col xs={24} sm={18} className="primary-info">
          <ProfileTabs astrologer={astrologer}/>
        </Col>
      </Row>
    </Container>
  )
}

function ProfileTabs({astrologer}) {

  const {t} = useTranslation();

  return (
    <div className="tabs">
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: t('about'),
            key: '1',
            children: <About astrologer={astrologer}/>,
          },
          {
            label: t('feedbacks'),
            key: '2',
            children: <Feedbacks userId={astrologer.userId}/>,
          },
        ]}
      />
    </div>
  )
}

