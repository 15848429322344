import React from 'react';
import {Typography, Upload, message} from 'antd';
import ImgCrop from 'antd-img-crop';
import imageCompression from 'browser-image-compression';

import useApi from 'core/hooks/useAPI';
import {changeAvatar} from 'core/apiRequests';

const {Link} = Typography;

const ChangeProfilePic = ({t, onChange}) => {

  const [trigger, loading] = useApi();

  const uploadImage = async options => {
    const {onSuccess, onError, file} = options;

    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.099,
      });

      const data = await trigger(changeAvatar(compressedFile));
      onSuccess("Ok");
      message.success(`Successfully updated your profile picture.`);
      onChange(data.avatarUrl);
    } catch (err) {
      onError({err});
      message.error(`Upload failed. ${err.data.message}`);
    }
  };

  return (
    <ImgCrop rotate>
      <Upload
        disabled={loading}
        name="file"
        accept="image/*"
        showUploadList={false}
        customRequest={uploadImage}
      >
        <Link strong>
          {t('settings.editProfile.changePic')}
        </Link>
      </Upload>
    </ImgCrop>
  );
};

export default ChangeProfilePic;