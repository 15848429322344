import React, {useContext} from 'react';
import {Col, Row, Select, Typography} from "antd";
import Container from "../Container";
import {astrologersFilterContext} from "core/state/AstrologersFilterProvider";

const {Text} = Typography;

const sortBy = [
  {
    "title": "Rating",
    "code": "RATING"
  },
  {
    "title": "Name",
    "code": "NAME"
  }
]

const Sorts = () => {

  const [filter, setFilter] = useContext(astrologersFilterContext);

  const handleChange = (value) => {
    setFilter({
      ...filter,
      sort: value
    });
  }

  return (
    <Container>
      <Row justify="end" align="middle" className="sorts">
        <Col>
          <Text strong type="secondary">
            {'Sort By:'}
          </Text>
        </Col>
        <Col>
          <Select
            onChange={handleChange}
            bordered={false}
            defaultValue={sortBy[0].code}
          >
            {
              sortBy.map((item, key) => (
                <Select.Option value={item.code} key={key}>{item.title}</Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>
    </Container>
  )
}

export default Sorts;