import {createContext, useState} from 'react';

export const astrologersFilterContext = createContext({});

const AstrologersFilterProvider = (props) => {

  const [filter, setFilter] = useState();

  return (
    <astrologersFilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </astrologersFilterContext.Provider>
  );
};

export default AstrologersFilterProvider;
