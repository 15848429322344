import React from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Col, Menu, Row} from 'antd';

import 'assets/styles/views/Consultations.less';

import Container from 'components/Container';
import {TitleLight} from 'components/Texts';
import ConsultationsList from 'components/consultations/ConsultationsList';

const items = [
  {label: 'Upcoming', key: 'upcoming'},
  {label: 'Previous', key: 'previous'},
];

const Consultations = () => {

  const navigate = useNavigate();
  const {pathname} = useLocation();

  const onClick = (e) => {
    navigate(`/consultations/${e.key}`);
  };

  return (
    <Container className="main-pv consultations">
      <TitleLight level={1}>
        My consultations
      </TitleLight>

      <Row>
        <Col span={24}>
          <Menu
            onClick={onClick}
            selectedKeys={[pathname.split('/')?.pop()]}
            mode="horizontal"
            items={items}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Routes>
            <Route path="upcoming" element={<ConsultationsList pending={true}/>}/>
            <Route path="previous" element={<ConsultationsList pending={false}/>}/>
            <Route path="/*" element={<Navigate to="/consultations/upcoming"/>}/>
          </Routes>
        </Col>
      </Row>

    </Container>
  );

};

export default Consultations;