import React, {useState} from 'react';
import {Col, Row, Typography} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {addDays, addMonths, format, isWithinInterval} from 'date-fns';

import {Calendar} from '../Pickers';
import 'assets/styles/components/booking/WebCalendar.less';

const {Text} = Typography;

export default function WebCalendar(
  {
    onDateChange,
    min = addDays(new Date(), -1),
    max = addDays(new Date(), 90)
  }
) {

  const [selectedDate, setSelectedDate] = useState(new Date());

  const onChange = (date) => {
    setSelectedDate(date);
    onDateChange?.(date);
  };

  return (
    <Calendar
      className="web-calendar"
      value={selectedDate}
      fullscreen={true}
      validRange={[min, max]}
      onChange={onChange}
      headerRender={config =>
        <Header
          config={config}
          min={min} max={max}
          selectedDate={selectedDate}
          onChange={onChange}
        />
      }
    />
  );
}

export function Header({config, min, max, selectedDate, onChange}) {

  const prevDisabled = !isWithinInterval(addMonths(selectedDate, -1), {start: min, end: max});
  const nextDisabled = !isWithinInterval(addMonths(selectedDate, 1), {start: min, end: max});

  return (
    <Row gutter={16} className="cal-header">
      <Col>
        <Text>
          {format(config.value, "MMM yyyy")}
        </Text>
      </Col>
      <Col flex={1}/>
      <Col>
        <button
          onClick={() => onChange(addMonths(config.value, -1))}
          disabled={prevDisabled}
        >
          <LeftOutlined/>
        </button>
      </Col>
      <Col>
        <button
          onClick={() => onChange(addMonths(config.value, 1))}
          disabled={nextDisabled}
        >
          <RightOutlined/>
        </button>
      </Col>
    </Row>
  );
}