import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {VideoSession} from '@neram/100ms-web';

import 'assets/styles/App.less';

import AppProvider from 'core/state/AppProvider';
import AuthService from 'core/services/AuthService';

import MobileAppAlert from 'components/MobileAppAlert';

import GeneralUser from "layout/GeneralUser";

import Privacy from 'views/Privacy';
import FAQ from 'views/FAQ';
import SsoLoginAndJoinMeet from 'views/SsoLoginAndJoinMeet';

function App() {

  return (
    <AppProvider>
      <div className="App">
        <BrowserRouter>
          <MobileAppAlert/>
          <Routes>
            <Route path="/conference/*" element={<VideoSession participantName={AuthService.getName()}/>}/>
            <Route exact path="/sso-join-meet/:sessionId" element={<SsoLoginAndJoinMeet/>}/>
            <Route exact path="/faq" element={<FAQ/>}/>
            <Route exact path="/legal-and-privacy" element={<Privacy/>}/>
            <Route path="/*" element={<GeneralUser/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </AppProvider>
  );
}

export default App;
