import * as React from 'react';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import generateCalendar from 'antd/es/calendar/generateCalendar';

import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker(dateFnsGenerateConfig);

const TimePicker = React.forwardRef((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref}/>;
});

TimePicker.displayName = 'TimePicker';

const Calendar = generateCalendar(dateFnsGenerateConfig);

export {DatePicker, TimePicker, Calendar};