const AuthService = {

  saveUserDetails: (user) => {
    localStorage.setItem("ag-user", JSON.stringify(user));
  },

  getUserDetails: () => {
    const user = localStorage.getItem("ag-user");
    if (!user)
      return null;
    return JSON.parse(user);
  },

  getToken: () => {
    return AuthService.getUserDetails()?.token;
  },

  clearUserDetails: () => {
    localStorage.clear();
  },

  getName: () => {
    return AuthService.getUserDetails()?.name;
  }

};

export default AuthService;

