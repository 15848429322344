import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Col, Row, Space, Skeleton, Typography, Form, Input, Button, notification, Modal} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {parseISO, format} from 'date-fns';

import {imgBaseUrl} from 'core/constants';
import useFetch from 'core/hooks/useFetch';
import {me, updateMe} from 'core/apiRequests';
import {requiredRule} from 'core/utils/FormUtils';
import useApi from 'core/hooks/useAPI';

import Spacer from '../Spacer';
import {DatePicker} from '../Pickers';
import SelectPhoneCode from '../InputPhone';
import ChangeProfilePic from './ChangeProfilePic';

/** @namespace res.dob */
/** @namespace res.country */
/** @namespace res.zip */
/** @namespace res.countryCode */
/** @namespace res.mobile */
/** @namespace values.phoneCode */
/** @namespace values.addressLines */

const avatarStyle = {
  width: '54px',
  height: '54px',
  fontSize: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
const dobStyle = {
  width: '100%'
};

const {Text} = Typography;

export default function EditProfile() {

  const [trigger, saving] = useApi();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [profile, loading, error] = useFetch(me, (res) => {
    form.setFieldValue('name', res.name);
    form.setFieldValue('dob', parseISO(res.dob));
    form.setFieldValue('addressLines', res.address?.lines?.join('\n'));
    form.setFieldValue('city', res.address?.city);
    form.setFieldValue('state', res.address?.state);
    form.setFieldValue('country', res.address?.country);
    form.setFieldValue('zip', res.address?.zip);
    form.setFieldValue('phoneCode', res.countryCode);
    form.setFieldValue('mobile', res.mobile);
    setAvatar(res.avatarUrl);
  });

  if (loading) {
    return (
      <div className="content">
        <Skeleton/>
      </div>
    );
  }

  if (error) {
    return null;
  }

  const onFinish = (values) => {
    const payload = {
      "name": values.name?.trim(),
      "dob": format(values.dob, 'yyyy-MM-dd'),
      "mobile": values.mobile?.trim(),
      "address": {
        "lines": values.addressLines?.split('\n').map(i => i.trim()),
        "city": values.city?.trim(),
        "state": values.state?.trim(),
        "zip": values.zip?.trim(),
        "country": values.country?.trim()
      },
      "countryCode": values.phoneCode?.trim()
    };

    trigger(updateMe(payload))
      .then(() => {
        notification.success({
          message: t('settings.editProfile.updateSuccess'),
          description: t('settings.editProfile.updateSuccessDesc'),
          placement: 'bottom',
        });
      })
      .catch(err => {
        Modal.error({
          title: t('settings.editProfile.updateFail'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  const onAvatarChange = (avatarUrl) => setAvatar(avatarUrl);

  return (
    <div className="content">

      <Space size={16}>
        <Avatar style={avatarStyle} src={imgBaseUrl + avatar}>
          <UserOutlined/>
        </Avatar>
        <Space direction="vertical" size={0}>
          <Text strong>
            {profile.email}
          </Text>
          <ChangeProfilePic t={t} onChange={onAvatarChange}/>
        </Space>
      </Space>

      <Spacer height={20}/>

      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark="optional"
        form={form}>

        <Form.Item
          label={t('settings.editProfile.name')}
          name="name"
          rules={[requiredRule(t('validation.required'))]}
        >
          <Input size="large"/>
        </Form.Item>

        <Form.Item
          label={t('settings.editProfile.dob')}
          name="dob"
          rules={[requiredRule(t('validation.required'))]}
        >
          <DatePicker size="large" format="MMMM d, yyyy" style={dobStyle}/>
        </Form.Item>

        <Form.Item
          label={t('settings.editProfile.mobile')}
          name="mobile"
          rules={[requiredRule(t('validation.required'))]}
        >
          <Input
            size="large"
            addonBefore={(
              <Form.Item name="phoneCode" noStyle>
                {SelectPhoneCode}
              </Form.Item>
            )}
          />
        </Form.Item>

        <Form.Item
          label={t('settings.editProfile.addr1')}
          name="addressLines"
          rules={[requiredRule(t('validation.required'))]}
        >
          <Input.TextArea rows={4}/>
        </Form.Item>

        <Row gutter={24}>
          <Col xs={24} sm={8}>
            <Form.Item
              label={t('settings.editProfile.city')}
              name="city"
              rules={[requiredRule(t('validation.required'))]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label={t('settings.editProfile.state')}
              name="state"
              rules={[requiredRule(t('validation.required'))]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label={t('settings.editProfile.country')}
              name="country"
              rules={[requiredRule(t('validation.required'))]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label={t('settings.editProfile.zip')}
              name="zip"
              rules={[requiredRule(t('validation.required'))]}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>

        <Spacer height={12}/>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={saving}>
            {t('submit')}
          </Button>
        </Form.Item>

      </Form>

    </div>
  );
}