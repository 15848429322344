import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Modal} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import useQueryParams from 'core/hooks/useQueryParams';
import AuthService from 'core/services/AuthService';
import useApi from 'core/hooks/useAPI';
import {me} from 'core/apiRequests';
import {useJoinMeet} from 'core/hooks/consultation/useJoinMeet';

const SsoLoginAndJoinMeet = () => {

  const {token} = useQueryParams();
  const navigate = useNavigate();
  const {sessionId} = useParams();
  const [trigger,] = useApi();
  const [, joinMeet] = useJoinMeet(sessionId);
  const {t} = useTranslation();

  useEffect(() => {
    alert(navigator.userAgent)
    AuthService.saveUserDetails({
      token: token
    });

    trigger(me)
      .then(data => {
        data.token = token;
        AuthService.saveUserDetails(data);
        joinMeet();
      })
      .catch(() => {
        Modal.error({
          title: t('consultation.tokenJoinError'),
          content: t('consultation.tokenJoinErrorDesc'),
          onOk: () => navigate('/')
        });
      });
  }, [sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w100 h100 center">
      <LoadingOutlined style={{fontSize: '44px', color: 'darkorange'}}/>
    </div>
  );
};

export default SsoLoginAndJoinMeet;