import {useEffect, useRef, useState} from 'react';
import ApiService from "core/services/ApiService";
import AuthService from "core/services/AuthService";

function useFetch(request, callback) {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(null);

  const requestRef = useRef(request);

  useEffect(() => {
    if (requestRef.current) {
      if (!loading) {
        setLoading(true);
      }
      ApiService.exchange(requestRef.current)
        .then(handleSuccess)
        .catch(handleFail)
    } else {
      if (loading) {
        setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestRef.current?.url, refresh]);

  const handleSuccess = res => {
    const {status, data} = res.data;

    if (status === 'success') {
      setData(data);
      if (error) {
        setError(null);
      }
      if (callback) {
        callback(data);
      }
      setLoading(false);
    }
  }

  const handleFail = reason => {
    if (reason.statusCode === 401) {
      AuthService.clearUserDetails();
      // redirect accordingly
      return;
    }

    const {data, status, error} = reason.response?.data;

    setData(null);
    setError({
      status,
      code: reason.response?.status,
      data,
      error
    });
    setLoading(false);
  }

  const refreshData = (newRequest = null) => {
    if (newRequest) {
      requestRef.current = newRequest
    }
    setData(null);
    setError(null);
    setRefresh(Math.random())
  }

  return [data, loading, error, refreshData];

}

export default useFetch;