import React, {useContext, useState} from 'react';
import {Button, Col, message, Modal, Row, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import OTPInput from "otp-input-react";

import useApi from 'core/hooks/useAPI';
import {EntryContext} from 'core/state/EntryProvider';
import {resendOtp, signUpVerify} from 'core/apiRequests';

import {SHOW_LOGIN} from 'views/Entry';
import Spacer from '../Spacer';


const {Title, Text} = Typography;

export default function SignupVerify() {

  const {t} = useTranslation();

  const [state, setState] = useContext(EntryContext);
  const [otp, setOtp] = useState(null);

  const [verifyTrigger, verifying] = useApi();
  const [resendTrigger, resending] = useApi();

  const onVerify = () => {
    verifyTrigger(signUpVerify(state.email, otp))
      .then(() => {
        localStorage.removeItem("otpData");
        setState({
          current: SHOW_LOGIN
        });
        Modal.success({
          title: t('entry.signUpVerify.successTitle'),
          content: t('entry.signUpVerify.successDesc'),
        });
      })
      .catch(err => {
        Modal.error({
          title: t('entry.error.verifyFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  const onResend = () => {
    resendTrigger(resendOtp(state.email))
      .then(() => {
        message.success(t('entry.signUpVerify.otpResent'))
          .then(() => {
            /* do nothing */
          });
      })
      .catch(err => {
        Modal.error({
          title: t('entry.error.resendFailed'),
          content: err?.data?.message ?? t('unknownError'),
        });
      });
  };

  return (
    <Space direction="vertical" className="w100">
      <Title level={4}>
        {t('entry.signUpVerify.title')}
      </Title>
      <Text>
        {t('entry.signUpVerify.subtitle')}
      </Text>
      <Spacer height={8}/>
      <OTPInput
        value={otp}
        onChange={setOtp}
        autoFocus
        OTPLength={6}
        otpType="number"
        disabled={false}
      />
      <Spacer height={8}/>
      <Row justify="start" align="middle">
        <Col>
          <Text>
            {t('entry.signUpVerify.notGet')}
          </Text>
        </Col>
        <Col>
          <Button type="link" loading={resending} onClick={onResend}>
            {t('entry.signUpVerify.resend')}
          </Button>
        </Col>
      </Row>
      <Spacer height={32}/>
      <Button type="primary" loading={verifying} onClick={onVerify}>
        {t('entry.signUpVerify.validate')}
      </Button>
    </Space>
  );
}