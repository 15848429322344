import React from 'react';
import {Col, Row, Skeleton} from "antd";
import Container from "../Container";
import Spacer from "../Spacer";

const Button = () => (
  <Skeleton.Button
    active
    size="large"
    shape="square"
    block={false}
    style={{width: '200px'}}
  />
)

export default function AstroProfileLoader() {
  return (
    <Container className="main-pt astro-profile">
      <Row>
        <Col span={6}>
          <Skeleton.Avatar active={true} shape="square" className="avatar-loader"/>
        </Col>
        <Col span={18} className="primary-info">
          <Skeleton active/>
          <Spacer height={44} width={'100%'}/>
          <Row gutter={[24, 24]}>
            <Col>
              <Button/>
            </Col>
            <Col>
              <Button/>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Spacer height={54}/>
        </Col>
        <Col xs={24} sm={6}>
          <Skeleton active/>
        </Col>
        <Col xs={24} sm={18} className="primary-info">
          <Skeleton active/>
        </Col>
      </Row>
    </Container>
  )
}