export const getExperience = (exp) => {
  if (!exp.years && !exp.months) {
    return 'Not Specified';
  }
  let value = "";
  if (exp.years > 0) {
    value += `${exp.years} year${exp.years === 1 ? '' : 's'}`;
  }
  if (exp.months > 0) {
    if (value.length > 0) {
      value += ", ";
    }
    value += `${exp.months} month${exp.months === 1 ? '' : 's'}`;
  }
  return value;
}
