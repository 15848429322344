import {useCallback, useEffect, useReducer, useRef, useState} from "react";
import ApiService from "core/services/ApiService";
import useApi from "./useAPI";

const pageSize = 30;

function useInfiniteScroll(request) {

  const [pageEnd, setPageEnd] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [trigger] = useApi();

  const requestRef = useRef(request);
  const cancelToken = useRef(null);

  const itemReducer = (state, action) => {
    switch (action.type) {
      case 'STACK_ITEMS':
        return {...state, items: state.items.concat(action.items)}
      case 'FETCHING_ITEMS':
        return {...state, fetching: action.fetching}
      case 'CLEAR_ITEMS':
        return {...state, items: []}
      default:
        return state;
    }
  };

  const [items, itemDispatch] = useReducer(
    itemReducer, {items: [], fetching: true}, undefined
  );

  const pageReducer = (state, action) => {
    switch (action.type) {
      case 'ADVANCE_PAGE':
        if (!items.fetching && !pageEnd) {
          return {...state, page: state.page + 1};
        } else {
          return state;
        }
      case 'RESET_PAGE':
        return {...state, page: 0};
      default:
        return state;
    }
  }

  const [pager, pagerDispatch] = useReducer(
    pageReducer, {page: 0}, undefined
  );

  useEffect(() => {
    if (!cancelToken.current) {
      cancelToken.current = ApiService.getCancelToken()
    }

    let itemRequest = {
      ...requestRef.current,
      options: {
        ...ApiService.getCancelOption(cancelToken.current)
      }
    }
    itemRequest.data.page = pager.page;
    itemRequest.data.size = pageSize;

    itemDispatch({type: 'FETCHING_ITEMS', fetching: true});

    trigger(itemRequest)
      .then(resItems => {
        if (resItems.length < pageSize) {
          setPageEnd(true);
        }
        itemDispatch({type: 'STACK_ITEMS', items: resItems});
        itemDispatch({type: 'FETCHING_ITEMS', fetching: false});
      })
      .catch(reason => console.log(reason));
  }, [itemDispatch, pager.page, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  let bottomBoundaryRef = useRef(null);

  const scrollObserver = useCallback(
    node => {
      new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (en.isIntersecting && en.intersectionRatio > 0) {
            pagerDispatch({type: 'ADVANCE_PAGE'});
          }
        });
      }).observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef]);

  const reloadData = (newRequest = null) => {
    ApiService.cancel(cancelToken.current);
    cancelToken.current = ApiService.getCancelToken();

    if (newRequest) {
      requestRef.current = newRequest;
      setRefresh(Math.random());
      itemDispatch({type: 'CLEAR_ITEMS'});
      pagerDispatch({type: 'RESET_PAGE'});
    }
  }

  return {
    itemData: items,
    bottomBoundaryRef,
    reloadData
  };
}

export default useInfiniteScroll;