import {useContext, useEffect} from "react";
import {Col, Row, Empty} from 'antd';

import useInfiniteScroll from 'core/hooks/useInfiniteScroll';
import useIsMobile from "core/hooks/useIsMobile";
import {allAstrologers} from 'core/apiRequests';
import {astrologersFilterContext} from "core/state/AstrologersFilterProvider";

import Container from 'components/Container';

import AstrologerGrid from './AstrologerGrid';
import AstrologerGridLoader from "./AstrologerGridLoader";


export default function List() {

  const [filter,] = useContext(astrologersFilterContext);

  let {itemData, bottomBoundaryRef, reloadData} = useInfiniteScroll(
    allAstrologers({
      sort: 'RATING',
    }, undefined, undefined),
  );

  useEffect(() => {
    if (filter) {
      reloadData(
        allAstrologers(filter, undefined, undefined)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  return (
    <Container className="astro-list">
      {
        itemData.fetching ? <PlaceHolders/> : (itemData.items.length > 0 ?
            <AstrologerItems items={itemData.items}/> : <NoDataView/>
        )
      }
      <div className="w100" style={{height: '1px'}} ref={bottomBoundaryRef}/>
    </Container>
  )
}

const PlaceHolders = () => {
  const {isMobile} = useIsMobile();
  return (
    <Row gutter={[32, 32]}>
      {
        Array(isMobile ? 2 : 6)
          .fill(0)
          .map((item, key) => (
            <Col xs={24} sm={8} key={key}>
              <AstrologerGridLoader data={item} key={key}/>
            </Col>
          ))
      }
    </Row>
  )
}

const AstrologerItems = ({items}) => (
  <Row gutter={[32, 32]}>
    {
      items.map((item, key) => (
        <Col xs={24} sm={8} key={key}>
          <AstrologerGrid data={item} key={key}/>
        </Col>
      ))
    }
  </Row>
)

const NoDataView = () => (
  <Row align="center" justify="center">
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
  </Row>
)

