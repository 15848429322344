import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {Modal} from 'antd';
import {isAndroid} from "react-device-detect";

import useApi from 'core/hooks/useAPI';
import {joinConsultation} from 'core/apiRequests';
import InApp from 'core/utils/InApp';
import AuthService from 'core/services/AuthService';

/** @namespace up.kbps **/

/** @namespace down.kbps **/
/** @namespace window.opera **/

export function useJoinMeet(id) {

  const navigate = useNavigate();
  const [joining, setJoining] = useState(false);
  const [trigger,] = useApi();
  const {t} = useTranslation();

  function joinMeet() {
    setJoining(true);
    const inApp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

    if (inApp.isInApp()) {
      if (isAndroid) {
        window.location = `intent:${process.env.REACT_APP_HOST}/sso-join-meet/${id}?token=${AuthService.getToken()}#Intent;end`;
      } else {
        Modal.error({
          title: t('consultation.joinError.inApp'),
          content: t('consultation.joinError.inAppDesc')
        });
      }
    } else {
      join();
    }
  }

  function join() {
    trigger(joinConsultation(id))
      .then(handleJoinSuccess)
      .catch(handleJoinFail);
  }

  const handleJoinSuccess = data => {
    setJoining(false);
    navigate(`/conference/${id}/preview/${data?.token}`);
  };

  const handleJoinFail = err => {
    setJoining(false);

    const {data} = err;
    if (data.error_cd === 10205) {
      Modal.error({
        title: t('consultation.joinError.meetEnded'),
        content: t('consultation.joinError.meetEndedDesc')
      });
      navigate(`/`);
    } else {
      Modal.error({
        title: t('techError'),
        content: t('techErrorDesc')
      });
    }
  };

  return [joining, joinMeet];
}

export default useJoinMeet;