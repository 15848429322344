import React, {useMemo} from 'react';
import 'assets/styles/components/booking/TimeSlot.less';
import {Tooltip} from 'antd';
import {differenceInMinutes, format, isAfter, parseISO} from "date-fns";
import classNames from "classnames";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const timeFormat = "h:mmaa";

/** @namespace slot.available */

export default function TimeSlot({slot, conf}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const startDate = parseISO(slot.startTime);
  const start = format(startDate, timeFormat);
  const end = format(parseISO(slot.endTime), timeFormat);
  const expired = useMemo(() => {
    if (conf.option === 'START_OF_DAY') {
      return true;
    }
    const now = new Date();
    if (isAfter(now, startDate)) {
      return true;
    }
    return conf.option === 'BEFORE_X_MINS' && differenceInMinutes(startDate, now) < conf.value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slot, conf]);

  let slotClass;
  if (slot.status === 'B') {
    slotClass = 'booked';
  } else if (slot.status === 'L') {
    slotClass = 'locked';
  } else if (expired) {
    slotClass = 'expired';
  } else if (slot.available) {
    slotClass = 'available';
  }

  const onClick = () => navigate(`/slot/${slot.id}/confirm`);

  const item = (
    <div className={classNames('slot', slotClass)} onClick={onClick}>
      <span className="time">
        {`${start} - ${end}`}
      </span>
      <span className="rate">
        {`₹${slot.rate}`}
      </span>
    </div>
  );

  switch (slotClass) {
    case 'booked':
      return withTooltip(item, t('book.tooltip.booked'));
    case 'locked':
      return withTooltip(item, t('book.tooltip.locked'));
    case 'expired':
      return withTooltip(item, t('book.tooltip.expired'));
    default:
      return item;
  }
}

function withTooltip(item, text) {
  return (
    <Tooltip placement="top" title={text}>
      {item}
    </Tooltip>
  );
}
