import {formatISO} from 'date-fns';
import {deviceDetect} from 'react-device-detect';

export const login = (email, password) => {
  return {
    url: '/login',
    method: 'post',
    data: {
      email, password
    }
  };
};

export const signUp = (data) => {
  return {
    url: '/signup',
    method: 'post',
    data
  };
};

export const signUpVerify = (email, otp) => {
  return {
    url: '/signup/verify',
    method: 'post',
    data: {
      email, otp
    }
  };
};

export const resendOtp = (email) => {
  return {
    url: '/otp/resend',
    method: 'post',
    data: {email}
  };
};

export const forgotPass = (email) => {
  return {
    url: '/forgotpassword',
    method: 'post',
    data: {email}
  };
};

export const forgotPassVerify = (email, otp, password) => {
  return {
    url: '/forgotpassword/verify',
    method: 'post',
    data: {email, otp, password}
  };
};

export const ssoLogin = (data) => {
  return {
    url: '/sso/login',
    method: 'post',
    data: data
  };
};

export const me = {
  url: '/me',
  method: 'get',
};

export const changeAvatar = (data) => {
  const formData = new FormData();
  formData.append("avatar", data);

  return {
    url: '/me/avatar',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
};

export const updateMe = (request) => {
  return {
    url: '/me',
    method: 'post',
    data: request
  };
};

export const allAstrologers = (
  {
    search = null,
    location = null,
    sort = 'RATING'
  }, page, size) => {
  return {
    url: '/consultant/all',
    method: 'post',
    data: {
      search,
      location,
      sort,
      page: page ?? 0,
      size: size ?? 10,
    }
  };
};

export const upcomingConsultations = (page = 0, size = 10, consultationTypes) => {
  return {
    url: '/consultations',
    method: 'post',
    data: {
      page,
      size,
      consultationTypes
    }
  };
};

export const previousConsultations = (page = 0, size = 10) => {
  return {
    url: '/consultation/history',
    method: 'post',
    data: {
      page,
      size
    }
  };
};

export const anAstrologer = (id) => {
  return {
    url: `/consultant/${id}`,
    method: 'get'
  };
};

export const dailyPredictions = {
  method: 'get',
  url: '/predictions/today'
};

export const featuredAstrologer = (page, size) => {
  return {
    url: '/consultant/recommended',
    method: 'post',
    data: {
      page: page ?? 0, size: size ?? 12,
    }
  };
};

export const locations = {
  url: '/domain/locations',
  method: 'get'
};

export const ratings = (userId) => {
  return {
    url: '/rating/score',
    method: 'post',
    data: {
      userId
    }
  };
};

export const reviews = (userId, page, size) => {
  return {
    url: '/rating/feedbacks',
    method: 'post',
    data: {
      userId,
      page: page ?? 0,
      size: size ?? 30,
    }
  };
};

export const getSlots = (consultantId, date) => {
  return {
    url: '/consultant/slots',
    method: 'post',
    data: {
      consultantId: consultantId,
      date: date
    }
  };
};

export const getSlot = (slotId) => {
  return {
    url: `/slot/${slotId}`,
    method: 'get',
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return {
    url: '/change-password',
    method: 'post',
    data: {
      oldPassword, newPassword
    }
  };
};

export const getLocation = () => {
  return {
    url: '/me/location',
    method: 'get'
  };
};

export const saveLocation = (latitude, longitude) => {
  return {
    url: '/me/location',
    method: 'post',
    data: {
      "lon": longitude,
      "lat": latitude
    }
  };
};

export const joinConsultation = (sessionId) => {
  return {
    url: '/consultation/join',
    method: 'post',
    data: {
      sessionId,
      provider: '100ms'
    }
  };
};

export const consultationDetails = (id) => {
  return {
    url: `/consultation/${id}/detail`,
    method: 'get',
  };
};

export const postFeedback = ({sessionId, rating, feedback}) => {
  return {
    url: '/consultation/feedback',
    method: 'post',
    data: {
      sessionId,
      rating,
      feedback,
    }
  };
};

export const bookSlot = (payload) => {
  return {
    url: '/consultation/book',
    method: 'post',
    data: payload
  };
};

export const bookHoroscopeMatching = (payload) => {
  return {
    url: '/consultation/matching/book',
    method: 'post',
    data: payload
  };
};

export const abortBooking = (orderId) => {
  return {
    url: '/consultation/book/abort',
    method: 'post',
    data: {
      orderId
    }
  };
};

export const bookVerify = (orderId, orderToken) => {
  return {
    url: '/consultation/book/verify',
    data: {
      orderId,
      orderToken
    },
    method: 'post'
  };
};

export const bookRetry = (orderId) => {
  return {
    url: '/consultation/book/payment/retry',
    method: 'post',
    data: {
      orderId
    }
  };
};

export const logError = (error, info) => {
  return {
    url: '/log/error',
    method: 'post',
    data: {
      timestamp: formatISO(new Date()),
      error: error.stack,
      info: JSON.stringify(info),
      client: JSON.stringify(deviceDetect())
    }
  };
};